import moment from 'moment';
import { fromISOString } from 'DateTimeOffset';
import LookupListItem from 'LookupListItem';
import ProposedValue from 'ProposedValue';

function property(value, returnType) {
	switch (returnType) {
		case 'DateTime':
			return moment(value).toDate();
		case 'DateTimeOffset':
			return fromISOString(value);
		case 'Nullable[[DateTime]]':
			if (value) {
				return moment(value).toDate();
			}
			return null;
		case 'Nullable[[DateTimeOffset]]':
			if (value) {
				return fromISOString(value);
			}
			return null;
		case 'TimeSpan':
			return moment.duration(value);
		case 'Nullable[[TimeSpan]]':
			if (value) {
				return moment.duration(value);
			}
			return null;
		default:
			return value;
	}
}

export default {
	lookup(value) {
		return value.map((item) => {
			return new LookupListItem(item.Code, item.Description, item.IconFontCode);
		});
	},
	property,
	proposedValue(value, returnType) {
		if (!value) {
			throw new Error('ProposedValue result should not be falsy');
		}

		return value.IsNoResult
			? ProposedValue.NoResult
			: new ProposedValue(property(value.Value, returnType));
	}
};
