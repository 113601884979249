import captionService from 'CaptionService';
import constants from 'Constants';
import MaterialDesignVueDialogService from './MaterialDesignVueDialogService';

class MaterialDesignNotesDialogService extends MaterialDesignVueDialogService {
	constructor() {
		super();
	}

	async showNotesAsync(entity, viewModel, state) {
		const title = await loadTitleAsync(entity);
			return await this.showDialogAsync(
				entity,
				state,
				constants.MaterialDesignPageTypes.Notes,
				viewModel,
				title,
			);
	}
}

async function loadTitleAsync(entity) {
	const humanReadableName = await entity.entityAspect.humanReadableName.loadAsync();
	const jobName = humanReadableName.caption;
	const title = [jobName];
	if (humanReadableName.name !== jobName) {
		title.push(humanReadableName.name);
	}

	return captionService.getString('db7ff800-4ed3-4dd9-aae8-b434ed22226f', '{0} Notes', title.join(' '));
}

export default new MaterialDesignNotesDialogService();
