import ruleService from 'RuleService';

export function shouldShowDataNotes(note) {
	if (!note) {
		return true;
	}

	const availableNoteTypes = ruleService.get(note.entityType.interfaceName).availableNoteTypes();

	return !availableNoteTypes || !availableNoteTypes.noteTypes.find((x) => x.description === note.ST_Description() && x.isTextOnly);
}
