import global from 'Global';
import DependencyGraphStrategy from './DependencyGraphStrategy';
import KnockoutStrategy from './KnockoutStrategy';

export function getDependencyStrategy(context) {
	const Ctor = global.featureFlags.useDependencyGraph
		? DependencyGraphStrategy
		: KnockoutStrategy;

	return new Ctor(context);
}
