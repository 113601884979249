import AsyncIndicator from 'AsyncIndicator';
import { AuthenticationResult, errorMessages, getAuthenticationResultName } from 'AuthenticationService';
import breeze from 'breeze-client';
import changePasswordDialogService from 'ChangePasswordDialogService';
import Constants from 'Constants';
import formFlowDefinitionProvider from 'FormFlowDefinitionProvider';
import formFlowInvoker from 'FormFlowInvoker';
import global from 'Global';
import initialPage from 'InitialPage';
import $ from 'jquery';
import logonFormRetriever from 'LogonFormRetriever';
import logonService from 'LogonService';
import navigationService from 'NavigationService';
import uiService from 'UIService';
import userSession from 'UserSession';
import variableStrategyFactory from 'VariableStrategyFactory';
import widgetService from 'WidgetService';
import windowManager from 'WindowManager';

/*! SuppressStringValidation Route */
const loginRoute = '#/login';

class AuthenticationController {
	constructor() {
		this._isLoggingOff = false;
	}

	ensureLoggedOn(context, callback) {
		const isNavigatingToPublicRoute = navigationService.isNavigatingToPublicRoute(context);

		if (!userSession.isLoggedOn() && !isNavigatingToPublicRoute) {
			let route = loginRoute;
			const fragment = $.url().attr('fragment');
			if (fragment && fragment !== '/') {
				const fragmentWithoutLeadingSlash = fragment.substr(1);
				route += '/' + encodeURIComponent(fragmentWithoutLeadingSlash);
			}
			context.redirect(route);
		} else {
			if (isNavigatingToPublicRoute || userSession.canAccessModule()) {
				callback();
			} else {
				uiService.loadNoModuleAccessPageAsync({ isAccessDenied: true, skipShowHelpInstructions: true });
			}
		}
	}

	async startLogonAsync(context) {
		if (userSession.isLoggedOn()) {
			navigationService.changePage(context.params.initialPage || initialPage.defaultPath);
		}
		else {
			const initialStatus = context.params.reason;
			initialPage.logonRedirectPath(context.params.initialPage);

			if (global.portalInfo.logonFormFlowPK) {
				return await startLogonFormFlowAsync(initialStatus);
			}
			else {
				return await showLogonFormAsync(initialStatus);
			}
		}
	}

	async logOffAsync(context) {
		if (!this._isLoggingOff) {
			const route = getRouteForRedirectToLogonPage(context.params.reason);
			try {
				this._isLoggingOff = true;
				const asyncIndicator = new AsyncIndicator();
				asyncIndicator.show();
				await logonService.logOffAsync();
			} finally {
				try{
					await windowManager.clearListeners();
					await windowManager.closeChildWindows();
				} catch (error) {
					$.noop(error);
				} finally {
					navigationService.changePageWithReload(route);
				}
			}
		}
	}

	changePassword() {
		changePasswordDialogService.changePassword();
	}
}

async function startLogonFormFlowAsync(initialStatus) {
	const formFlowPK = global.portalInfo.logonFormFlowPK;
	const definition = await formFlowDefinitionProvider.getAsync(formFlowPK);
	const entity = await getLogonModelAsync(definition.EntityTypeName, initialStatus);
	return await formFlowInvoker.invokeWithStrategiesAsync(
		formFlowPK,
		[variableStrategyFactory.makeConstantStrategy(entity)],
		{
		hideSaveCloseButtons: true,
		uiContextOptions: {
		headerTemplate: 'LogonHeaderTemplate.ejs',
			formType: Constants.PageTypes.LogonTask,
			formOptions: {
				isLogonPage: true,
				title: null,
			},
		},
	});
}

async function showLogonFormAsync(initialStatus) {
	const entityName = await logonFormRetriever.getEntityNameAsync();
	return await uiService
		.loadFormAsync(Constants.PageTypes.Form, {
			skipShowHelpInstructions: true,
			form: logonFormRetriever.getFormAsync(),
			viewModel: getLogonModelAsync(entityName, initialStatus),
			isLogonPage: true,
		})
		.spread(($view) => {
			if (!global.materialDesign) {
				$view.addClass('g-login-page');
			}
			const $pageShell = $view.find(Constants.CssClasses.PageShell.Selector);
			widgetService.utils.setInitialFocus($pageShell);
	});
}

async function getLogonModelAsync(entityName, initialStatus) {
	const entityManager = await breeze.EntityManager.forEntityTypeAsync(entityName);
	const entity = await entityManager.createEntityWithKeyAsync(entityName);
	if (initialStatus) {
		const message = errorMessages.getForResult(initialStatus, global.appName);
		entity.LogonStatusMessage(message);
	}
	return entity;
}

function getRouteForRedirectToLogonPage(authenticationResult) {
	let route = loginRoute;

	const resultsForSessionExpiryRedirect = [
		AuthenticationResult.SessionExpired,
		AuthenticationResult.SessionEvicted,
		AuthenticationResult.SessionNotFound
	];

	const shouldRedirectForSessionExpiry = resultsForSessionExpiryRedirect.some((authenticationResultForSessionExpiryRedirect) => authenticationResult === getAuthenticationResultName(authenticationResultForSessionExpiryRedirect));

	if (shouldRedirectForSessionExpiry) {
		const fragment = $.url().attr('fragment');
		if (fragment && fragment !== '/') {
			const fragmentWithoutLeadingSlash = fragment.substr(1);
			route += '/' + encodeURIComponent(fragmentWithoutLeadingSlash) + '/' + authenticationResult;
		}
	}

	return route;
}

export default new AuthenticationController();
