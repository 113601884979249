import ajaxService from 'AjaxService';
import authConstants from 'AuthenticationServiceConstants';
import captionService from 'CaptionService';
import { LogonProviderType, DropDownDisplayMode } from 'Constants';
import contextChangeService from 'ContextChangeService';
import dialogService from 'DialogService';
import errors from 'Errors';
import global from 'Global';
import materialDesignDialogService from 'MaterialDesignDialogService';
import { loadTemplateAsync } from 'ModuleLoader';
import navigationService from 'NavigationService';
import NotificationSummary from 'NotificationSummary';
import NotificationType from 'NotificationType';
import Notifications from 'Notifications';
import { UserType } from 'UserTypeConstants';
import windowManager from 'WindowManager';
import Promise from 'bluebird';
import ko from 'knockout';

async function ajaxAsync() {
	try {
		return await ajaxService.rawAjaxAsync.apply(ajaxService, arguments);
	} catch (error) {
		handleAjaxError(error);
	}
}

function authAjaxAsync(uri, data) {
	/*! StartNoStringValidationRegion Suppressed in initial refactor */
	const request = {
		url: global.serviceUri + 'auth/v2/' + uri,
		type: 'post',
		dataType: 'json',
		contentType: 'application/json',
		data: JSON.stringify(data),
		isSensitive: true,
	};

	/*! EndNoStringValidationRegion */
	return ajaxAsync(request);
}

function handleAjaxError(error) {
	if (error instanceof ajaxService.AjaxError) {
		let errorMessage;
		let authenticationResult;
		if (error.status) {
			if (errors.isDbUpgradeError(error)) {
				errorMessage = captionService.getString(
					'9e930565-85fa-4f61-873a-d66793b2ebee',
					'The application is currently being upgraded, please try again later.'
				);
			} else {
				const authenticationResultName = error.getResponseHeader(
					GlowAuthenticationResultHeaderName
				);
				if (authenticationResultName) {
					authenticationResult = AuthenticationResult[authenticationResultName];
				} else {
					errorMessage = authConstants.getHttpErrorMessage(error.status);
				}
			}
		}

		if (authenticationResult === undefined) {
			authenticationResult = AuthenticationResult.AbnormalFailure;
		}

		throw new AuthenticationError(authenticationResult, errorMessage, null, error);
	} else {
		throw error;
	}
}

export const credentials = {
	async claimSsoTokenAsync(ssoToken) {
		/*! SuppressStringValidation - part of URI path */
		const response = await authAjaxAsync('credential/sso', { authenticationToken: ssoToken });

		if (response.result === AuthenticationResult.Success) {
			return {
				userName: response.userDisplayName,
				userKey: response.userKey,
				authenticationToken: response.authenticationToken,
				authenticationSource: response.authenticationSource,
			};
		}
	},

	async claimAsync(logonProviderType, userName, password, serialNumber, deviceType) {
		/*! SuppressStringValidation - part of URI path */
		const response = await authAjaxAsync('credential/claim/' + logonProviderType, {
			userName,
			password,
			serialNumber,
			deviceType,
		});
		if (
			response.result === AuthenticationResult.Success ||
			response.result === AuthenticationResult.PasswordChangeRequired ||
			response.result === AuthenticationResult.ContextChangeRequired
		) {
			return response;
		} else {
			throw new AuthenticationError(response.result, null, response);
		}
	},

	async selectUserContextAsync(authenticationToken, userKey, branchKey, departmentKey) {
		const data = {
			authenticationToken,
			logonProviderType: global.portalInfo.userType,
			userKey,
			branchKey,
			departmentKey,
		};

		/*! SuppressStringValidation - part of URI path */
		const response = await authAjaxAsync('credential/context/select', data);

		if (response.result === AuthenticationResult.Success) {
			return response.authenticationToken;
		} else {
			throw new AuthenticationError(response.result, null, response);
		}
	},

	async externalAsync(authenticationSource, userKey, keysToReport) {
		const data = {
			authenticationSource,
			logonProviderType: global.portalInfo.userType,
			userKey,
			externalLogonValues: keysToReport,
		};

		/*! SuppressStringValidation - part of URI path */
		const response = await authAjaxAsync('credential/external', data);
		if (response.result === AuthenticationResult.Success) {
			return response;
		} else {
			throw new AuthenticationError(response.result, null, response);
		}
	},

	async revokeAsync(authenticationToken) {
		/*! SuppressStringValidation - part of URI path */
		await authAjaxAsync('credential/revoke', { authenticationToken });
	},

	async challengeAsync(authenticationSource) {
		/*! SuppressStringValidation - part of URI path */
		const response = await authAjaxAsync('credential/challenge', { authenticationSource });
		if (
			response.result === AuthenticationResult.Success ||
			response.result === AuthenticationResult.ThirdPartyUserValidationRequired
		) {
			return response;
		} else {
			throw new AuthenticationError(response.result, null, response);
		}
	},

	listContextsAsync(authenticationToken, userKey, branchKey, departmentKey) {
		const logonProviderType = global.portalInfo.userType;

		/*! SuppressStringValidation - part of URI path */
		return authAjaxAsync('credential/context/list', {
			authenticationToken,
			logonProviderType,
			userKey,
			branchKey,
			departmentKey,
		});
	},
};

export const session = {
	async beginAsync(authenticationToken, tokenType, sessionType, existingSession) {
		const request = { authenticationToken, tokenType, sessionType: resolveSessionType(sessionType) };
		if (existingSession) {
			request.existingSession = existingSession;
		}

		/*! SuppressStringValidation - part of URI path */
		const response = await authAjaxAsync('session/begin', request);
		if (response.result === AuthenticationResult.Success) {
			if (
				response.userInfo.identityProvider === UserType.Person &&
				global.portalInfo.userType !== LogonProviderType.Person
			) {
				const token = await session.changeUserAsync({ authenticationToken });
				if (token) {
					return session.beginAsync(token, tokenType, sessionType, existingSession);
				} else {
					throw new AuthenticationError(
						response.result,
						captionService.getString(
							'b73fba63-a1dc-4192-bdf8-b34df587a4fa',
							'You must select a user to log in.'
						),
						response
					);
				}
			}

			return {
				userInfo: response.userInfo,
				authenticationToken: response.substitutedRefreshToken || authenticationToken,
			};
		} else if (response.result === AuthenticationResult.ContextChangeRequired) {
			const token = await session.changeContextAsync({
				authenticationToken,
				userPK: response.userInfo.identityKey,
			});
			return session.beginAsync(token, tokenType, sessionType, existingSession);
		} else {
			throw new AuthenticationError(response.result, null, response);
		}
	},

	checkAsync() {
		/*! StartNoStringValidationRegion - part of URI path */
		const request = {
			url: global.serviceUri + 'auth/v2/session/check',
			type: 'get',
			dataType: 'json',
		};
		/*! EndNoStringValidationRegion */
		return ajaxAsync(request);
	},

	destroyAsync() {
		/*! StartNoStringValidationRegion - part of URI path */
		const request = {
			url: global.serviceUri + 'auth/v2/session/destroy',
			type: 'post',
		};
		/*! EndNoStringValidationRegion */
		return ajaxAsync(request);
	},

	async destroyAllAsync(sessionType) {
		/*! StartNoStringValidationRegion - part of URI path */
		const request = {
			url: global.serviceUri + 'auth/v2/session/destroyAll',
			type: 'post',
			contentType: 'application/json',
			data: JSON.stringify({ sessionType: resolveSessionType(sessionType) }),
		};
		/*! EndNoStringValidationRegion */
		return await ajaxAsync(request);
	},

	async changeContextAsync(sessionData, canSkip) {
		const contextData = await credentials.listContextsAsync(
			sessionData.authenticationToken,
			sessionData.userPK
		);
		return new ContextChangeViewModel(contextData, sessionData, canSkip).changeContextAsync();
	},

	async changeUserAsync(sessionData) {
		const { contexts } = await credentials.listContextsAsync(sessionData.authenticationToken);
		const context = await contextChangeService.selectUserAsync(contexts);

		if (context) {
			return credentials.selectUserContextAsync(
				sessionData.authenticationToken,
				context.userKey
			);
		}
	},
};

export const errorMessages = {
	getDefault: authConstants.getDefaultErrorMessage,
	getForResult: authConstants.getErrorMessage,
	getForHttpError: authConstants.getHttpErrorMessage,
};

export const AuthenticationClaimType = authConstants.AuthenticationClaimType;
export const AuthenticationResult = authConstants.AuthenticationResult;
export const SessionType = authConstants.SessionType;
export const GlowAuthenticationResultHeaderName = authConstants.GlowAuthenticationResultHeaderName;

export function getAuthenticationResultName(authenticationResult) {
	return authConstants.AuthenticationResultStringTable[authenticationResult];
}

export class AuthenticationError extends Error {
	constructor(authenticationResult, message, claimDetails, innerError) {
		if (!message) {
			if (authenticationResult) {
				message = authConstants.getErrorMessage(authenticationResult, global.appName);
			} else {
				message = authConstants.getDefaultErrorMessage();
			}
		}

		super(message);
		this.name = 'AuthenticationError';
		this.authenticationResult = authenticationResult;
		this.claimDetails = claimDetails || null;
		this.innerError = innerError;
	}

	getData() {
		return [{ name: 'AuthenticationResult', value: this.authenticationResult }];
	}
}

function resolveSessionType(sessionType) {
	if (!sessionType) {
		return SessionType.General;
	}

	if (!Object.values(SessionType).includes(sessionType)) {
		throw new AuthenticationError(
			AuthenticationResult.AbnormalFailure,
			`Unsupported SessionType: [${sessionType}]`
		);
	}

	return sessionType;
}

class ContextChangeViewModel {
	constructor(contextData, { authenticationToken, userPK, branchPK, departmentPK }, canSkip) {
		this._authenticationToken = authenticationToken;
		this._userPK = userPK;
		this._originalBranchKey = branchPK;
		this._originalDepartmentKey = departmentPK;
		this._canSkip = canSkip;

		this.branchKey = ko.observable(this._originalBranchKey);
		this.departmentKey = ko.observable(this._originalDepartmentKey);

		const sorter = (a, b) => (a.name === b.name ? 0 : a.name < b.name ? -1 : 1);
		this.branches = ko.observableArray(contextData.branchInfos.sort(sorter));
		this.departments = ko.pureComputed(() =>
			contextData.contexts
				.reduce((result, context) => {
					if (context.branchKey === this.branchKey()) {
						const matchedDepartment = contextData.departmentInfos.find(
							(d) => d.key === context.departmentKey
						);
						if (matchedDepartment) {
							result.push(matchedDepartment);
						}
					}
					return result;
				}, [])
				.sort(sorter)
		);

		this.notifications = new Notifications();
		this.notificationSummary = new NotificationSummary(this);

		this.hasChanged = ko.pureComputed(
			() =>
				this._originalBranchKey !== this.branchKey() ||
				this._originalDepartmentKey !== this.departmentKey()
		);
		this.canSave = ko.pureComputed(() => this.hasChanged() && !this.notifications.hasAlerts());

		this.branchKey.subscribe(() => this.notifications.removeAll());
		this.departmentKey.subscribe(() => this.notifications.removeAll());
		this.DropDownDisplayMode = DropDownDisplayMode;
	}

	changeContextAsync() {
		this._deferred = Promise.deferred();
		/*! SuppressStringValidation CSS */
		const resultSave = 'save';
		const buttonOptions = [
			{
				caption: dialogService.buttonTypes.Cancel().text,
				result: dialogService.buttonTypes.Cancel().value,
				bindingString: 'click: cancel.bind($data)',
				isDismiss: true,
			},
			{
				caption: captionService.getString('4946F7A3-CD91-46F1-9C5D-9320349940F0', 'Save'),
				result: resultSave,
				bindingString:
					'asyncClick: saveAsync.bind($data), css: { disabled: !canSave }, enable: canSave',
				isPrimary: true,
			},
		];

		if (materialDesignDialogService.canShowChangeBranchDepartmentDialog()) {
			materialDesignDialogService.showChangeBranchDepartmentDialogAsync(this);
		} else {
			/*! SuppressStringValidation CSS */
			const dialogCss = 'g-dialog-change-branch-department';
			this._dialogInfo = dialogService.showDialogAsync({
				viewModel: this,
				title: captionService.getString(
					'62D6A0E5-09E8-4C1B-ABF0-ABDFA62C1E06',
					'Select Branch and Department'
				),
				bodyAllowHtml: true,
				bodyDeferred: loadTemplateAsync('ChangeBranchAndDepartment.html'),

				dialogCss,
				closeOnDismissOnly: true,
				buttonOptions,
				includeValidationSummary: !!this.notificationSummary,
			});
		}

		return this._deferred.promise();
	}

	async saveAsync() {
		if (!this.canSave.peek()) {
			return;
		}
		try {
			const token = await credentials.selectUserContextAsync(
				this._authenticationToken,
				this._userPK,
				this.branchKey.peek(),
				this.departmentKey.peek()
			);
			if (token) {
				// dialog hidden on successful save,
				// if the new token fails context security check (in the same promise chain),
				// we can show a new dialog again without overlapping the one shown before.
				dialogService.hide(await this._dialogInfo);
				this._deferred.resolve(token);
			}
		} catch (error) {
			if (error instanceof AuthenticationError) {
				if (
					error.authenticationResult ===
					AuthenticationResult.ContextInsufficientPrivileges
				) {
					this.notifications.push({
						Level: NotificationType.Error,
						propertyName: '.',
						Text: captionService.getString(
							'c881635e-b016-4ffd-8c65-52ce1eee9351',
							"You do not have the security right to log in to this branch or department.\nYou may ask your system administrator to grant you this security right.\n\nThis particular security right is called 'Login Branches and Departments', and is found at the bottom of the Security Tree View on the Staff form and Group form."
						),
						caption: captionService.getString(
							'094c93df-f155-4179-9e86-833314aa3228',
							'Security Rights Denied'
						),
					});
					return null;
				} else {
					await dialogService.alertAsync(
						NotificationType.Error,
						captionService.getString(
							'e4e307b8-8a19-44aa-9430-eaabe8cf34e9',
							'An unexpected error occurred while changing branch and department. Please try again. You may need to reenter your credentials.'
						),
						captionService.getString(
							'ee11b39a-e5dd-4a36-b48a-53a333467f5e',
							'Error Changing Branch and Department'
						)
					);
					this.reloadPage();
				}
			} else {
				throw error;
			}
		}
	}

	cancel() {
		this._canSkip ? this._deferred.resolve() : this.reloadPage();
	}

	reloadPage() {
		windowManager.clearListeners();
		windowManager.closeChildWindows();
		navigationService.reloadPage();
	}
}
