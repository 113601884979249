// Wrapper around WebPack require functions.
// Remove once we have proper tooling in place (e.g. for linting and unit tests).

/*! StartNoStringValidationRegion No captions in this file. */

import ajaxService from 'AjaxService';
import errors from 'Errors';
import { loadAsync } from 'RulesetLoader';

export function tryGetBusinessRule(resourceName) {
	return tryGetModuleValue(require.cache[require.resolveWeak(`BusinessRules/${resourceName}`)]);
}

export function tryGetVueComponent(componentName) {
	// A compiled Vue Component requires us to explicitly look for the default export because of the way the vue-loader creates its modules.
	return tryGetModuleValue(require.cache[require.resolveWeak(`VueComponents/${componentName}.vue`)]);
}

export function tryGetVueExtender(extenderName) {
	const context = require.context('VueExtenders', false, /.+Extender\.*$/, 'weak');
	return tryGetModuleValue(require.cache[context.resolve(`./${extenderName}`)]);
}

export function tryGetWidget(widgetName) {
	const context = require.context('Widgets', false, /gw.*\.*$/, 'weak');
	return tryGetModuleValue(require.cache[context.resolve(`./${widgetName}`)]);
}

export function loadActivityInvokerAsync(kind) {
	return wrapAsync(() => import(`FormFlowActivityInvokers/${kind}`));
}

export function loadBusinessRuleAsync(resourceName) {
	return wrapAsync(() => import(`BusinessRules/${resourceName}`));
}

export function loadBusinessViewModelAsync(viewModelName) {
	return wrapAsync(() => import(`BusinessViewModels/${viewModelName}`));
}

export function loadControllerAsync(controllerName) {
	return wrapAsync(() => import(`Controllers/${controllerName}Controller`));
}

export function loadEntityCustomFilterStrategyFactoriesAsync() {
	return wrapAsync(() => import('EntityCustomFilterStrategyFactoriesLoader!'));
}

export function loadFilterFactoryAsync(factoryName) {
	return wrapAsync(() => import(`Filters/${factoryName}FilterFactory`));
}

export function loadMarkerWithLabelAsync() {
	return wrapAsync(() => import('@google/markerwithlabel'));
}

export function loadMomentTimeZonesAsync() {
	return wrapAsync(() => import('moment-timezone'));
}

export function loadNativeBridgeAsync(providerName) {
	return wrapAsync(() => import(/* webpackInclude: /.+Provider\.[j|t]s$/ */`Shared/NativeBridge/${providerName}`));
}

export function loadPreviewModeErrorHandlerAsync() {
	return wrapAsync(() => import(/* webpackChunkName: "PreviewModeErrorHandler" */'PreviewModeErrorHandler'));
}

export function loadRulesetAsync(routeName) {
	return wrapAsync(() => loadAsync(ajaxService, routeName));
}

export function loadTemplateAsync(templateName) {
	return wrapAsync(() => import(`WidgetTemplates/${templateName}`));
}

export function loadVueComponentAsync(componentName) {
	return wrapAsync(() => import(`VueComponents/${componentName}.vue`));
}

export function loadVueExtenderAsync(extenderName) {
	return wrapAsync(() => import(/* webpackInclude: /.+Extender\.[j|t]s$/ */`VueExtenders/${extenderName}`));
}

export function loadWidgetAsync(widgetName) {
	return wrapAsync(
		() => import(
			/* webpackInclude: /gw.*\.[j|t]s$/ */
			/* webpackExclude: /__tests__/ */
			`Widgets/${widgetName}`));
}

export function loadWorkflowAuditLogsViewModelAsync() {
	return wrapAsync(() => import(/* webpackChunkName: "WorkflowAuditLogsViewModel" */'WorkflowAuditLogsViewModel'));
}

export function loadWorkflowEventsViewModelAsync() {
	return wrapAsync(() => import(/* webpackChunkName: "WorkflowEventsViewModel" */'WorkflowEventsViewModel'));
}

export function loadWorkflowExceptionsViewModelAsync() {
	return wrapAsync(() => import(/* webpackChunkName: "WorkflowExceptionsViewModel" */'WorkflowExceptionsViewModel'));
}

export function loadWorkflowMilestonesViewModelAsync() {
	return wrapAsync(() => import(/* webpackChunkName: "WorkflowMilestonesViewModel" */'WorkflowMilestonesViewModel'));
}

export function loadWorkflowTasksViewModelAsync() {
	return wrapAsync(() => import(/* webpackChunkName: "WorkflowTasksViewModel" */'WorkflowTasksViewModel'));
}

export function loadWorkflowTriggersViewModelAsync() {
	return wrapAsync(() => import(/* webpackChunkName: "WorkflowTriggersViewModel" */'WorkflowTriggersViewModel'));
}

export function loadAvailableColumnsStrategyProviderAsync() {
	return wrapAsync(() => import(/* */'AvailableColumnsStrategyProvider'));
}

function tryGetModuleValue(module) {
	return module && getModuleValue(module.exports);
}

async function wrapAsync(importer, retries = 2) {
	try {
		const imported = await importer();
		return getModuleValue(imported);
	} catch (error) {
		if (error.code === 'MODULE_NOT_FOUND') {
			throw new errors.UnknownModuleError(error.message, error);
		}

		let url;
		if (error.constructor !== Error || typeof error.type !== 'string' || typeof (url = error.request) !== 'string') {
			throw error;
		}

		if (error.type !== errors.ScriptLoadErrorType.Error || retries === 0) {
			throw new errors.ScriptLoadError(error.type, url, null, error);
		}
		else {
			try {
				await ajaxService.ajaxAsync({ url, dataType: 'text', headers: { 'cache-control': 'no-cache' } });
			} catch (internalError) {
				if (internalError instanceof ajaxService.AjaxError) {
					throw new errors.ScriptLoadError(error.type, url, internalError.status, error);
				}
				throw internalError;
			}
			const result = await wrapAsync(importer, --retries);
			return result;
		}
	}
}

/*! EndNoStringValidationRegion */

export function getModuleValue(exports) {
	return exports.default || exports;
}
