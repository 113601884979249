import { CancellationError } from 'Errors';
import { getVueInstance, loadVueComponentDefinitionsAsync } from 'VueComponentService';
import { setBindingContext } from 'VueHooks/BindingContextHook.ts';
import { setFormId } from 'VueHooks/FormIdHook.ts';
import { setKnockoutContext } from 'VueHooks/KnockoutContextHook.ts';
import { setMessageBus } from 'VueHooks/MessageBusProviderHook.ts';
import { setValidationRegistrar } from 'VueHooks/ValidationRegistrarHook';
import { setPageViewModel } from 'VueHooks/PageViewModelHook.ts';
import { setContentViewModel } from 'VueHooks/ContentViewModelHook.ts';
import wtgMaterialUi from 'VuePlugins/WtgMaterialUi';
import wtgPinia from 'VuePlugins/WtgPinia';
import ko from 'knockout';
import Vue, { ref } from 'vue';

class VueFactory {
	constructor() {
	}

	createVueInstanceAsync(options) {
		const container = options.contentContainer;

		return loadVueComponentDefinitionsAsync(container)
			// eslint-disable-next-line rulesdir/prefer-async-await
			.then((components) => {
				if (getVueInstance(container)) {
					throw new Error('Vue instance already exists');
				}

				if (!document.contains(container)) {
					/*! SuppressStringValidation Error Message */
					throw new CancellationError('Could not create an instance whose container is not in the document');
				}

				const parent = findParentVueInstance(container);
				if (options.requiresParent && !parent) {
					return null;
				}

				const componentOptions = {
					el: container,
					parent,
					name: options.name,
					comments: true,
					components,
					created() {
						if (options.bindingContext) {
							setPageViewModel(options.bindingContext);
							setContentViewModel(options.bindingContext.contentViewModel);
							setBindingContext(ref(options.bindingContext));
							setMessageBus(ref(options.bindingContext));
						}
						if (options.validationRegistrar) {
							setValidationRegistrar(options.validationRegistrar);
						}
						if (options.knockoutContext) {
							setKnockoutContext(options.knockoutContext);
						}
						if (options.formId) {
							setFormId(options.formId);
						}
					},
					data: options.data || noData,
					methods: options.methods || {},
				};
				if (!parent) {
					componentOptions.wtgMaterialUi = wtgMaterialUi;
					componentOptions.pinia = wtgPinia;
				}

				const instance = new Vue(componentOptions);
				ko.utils.domNodeDisposal.addDisposeCallback(instance.$el, () => {
					instance.$destroy();
				});

				return instance;
			});
	}
}

function noData() {
	return {};
}

function findParentVueInstance(element) {
	let parent = element.parentNode;

	while (parent) {
		const instance = getVueInstance(parent);
		if (instance) {
			return instance;
		}
		parent = parent.parentNode;
	}
}

export default new VueFactory();
