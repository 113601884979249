import { type ComplexType, type Entity, type EntityManager, type EntityType } from "breeze-client";

export function getEntityManager(entity: Entity): EntityManager {
  const { entityManager } = entity.entityAspect;
  if (!entityManager) {
    throw new Error(`Cannot get EntityManager for a detached ${getInterfaceName(entity)}.`);
  }

  return entityManager;
}

export function getInterfaceName(entity: Entity | EntityType | ComplexType): string {
  const entityType = "entityType" in entity ? entity.entityType : entity;
  return "interfaceName" in entityType && typeof entityType.interfaceName === "string" ? entityType.interfaceName : "";
}

export function getTableCode(entity: Entity): string {
  const entityType = entity.entityType;
  return "tableCode" in entityType && typeof entityType.tableCode === "string" ? entityType.tableCode : "";
}

export function getPrimaryKey(entity: Entity): string {
  return entity.entityAspect.getKey().values[0];
}

export function isAddedOrModified(entity: Entity): boolean {
  const { entityState } = entity.entityAspect;
  return entityState.isAdded() || entityState.isModified();
}

export function isDeletedOrDetached(entity: Entity): boolean {
  const { entityState } = entity.entityAspect;
  return entityState.isDeleted() || entityState.isDetached();
}

export function isEntity(input: unknown): input is Entity {
  return !!input && typeof input === "object" && "entityAspect" in input;
}

export function ensureResourceName(entityType: EntityType): string {
  const result = entityType.defaultResourceName;
  if (!result) {
    throw new Error("No resource name for entity type: " + getInterfaceName(entityType));
  }

  return result;
}

export function isComplexType(entityType: EntityType | ComplexType): entityType is ComplexType {
  return entityType.isComplexType;
}

export function deactivateEntity(entity: Entity): void {
  const propertyName = getIsActivePropertyName(entity);
  if (!propertyName) {
    throw new Error(`Entity of type '${getInterfaceName(entity)}' does not support deactivation`);
  }
  const value = entity.getProperty(propertyName);
  if (propertyName !== null) {
    if (getIsCancelable(entity)) {
      if (value === false) {
        entity.setProperty(propertyName, true);
      } else if (value === "N") {
        entity.setProperty(propertyName, "Y");
      }
    } else {
      if (value === true) {
        entity.setProperty(propertyName, false);
      } else if (value === "Y") {
        entity.setProperty(propertyName, "N");
      }
    }
  }
}

export function activateEntity(entity: Entity): void {
  const propertyName = getIsActivePropertyName(entity);
  if (propertyName) {
    const value = entity.getProperty(propertyName);
    if (getIsCancelable(entity)) {
      if (value === true) {
        entity.setProperty(propertyName, false);
      } else if (value === "Y") {
        entity.setProperty(propertyName, "N");
      }
    } else {
      if (value === false) {
        entity.setProperty(propertyName, true);
      } else if (value === "N") {
        entity.setProperty(propertyName, "Y");
      }
    }
  }
}

export function isEntityActive(entity: Entity): boolean {
  const propertyName = getIsActivePropertyName(entity);
  let value = !propertyName || entity.getProperty(propertyName) === true || entity.getProperty(propertyName) === "Y";
  if (getIsCancelable(entity)) {
    value = !value;
  }

  return value;
}

export function getIsActivePropertyName(entity: Entity): string | undefined {
  // TODO: implement correctly similar to EntityManagerExtensions instead of relying on BreezeInit setting these properties
  if (!("isActivePropertyName" in entity.entityType && typeof entity.entityType.isActivePropertyName === "string")) {
    return;
  }
  return entity.entityType.isActivePropertyName;
}

export function getIsCancelable(entity: Entity): boolean | undefined {
  // TODO: implement correctly similar to EntityManagerExtensions instead of relying on BreezeInit setting these properties
  if (!("isCancellable" in entity.entityType && typeof entity.entityType.isCancellable === "boolean")) {
    return;
  }
  return entity.entityType.isCancellable;
}

export async function getTypeDescriptionAsync(entity: Entity): Promise<string> {
  // TODO: implement correctly by extracting this function from BreezeInit
  if (
    !(
      "getTypeDescriptionAsync" in entity.entityAspect &&
      typeof entity.entityAspect.getTypeDescriptionAsync === "function"
    )
  ) {
    return "";
  }
  return await entity.entityAspect.getTypeDescriptionAsync();
}

export function getIsNotExpandable(entity: Entity | EntityType | ComplexType): boolean | undefined {
  const entityType = "entityType" in entity ? entity.entityType : entity;
    // TODO: implement correctly similar to EntityManagerExtensions instead of relying on BreezeInit setting these properties
    if (!("isNotExpandable" in entityType && typeof entityType.isNotExpandable === "boolean")) {
      return;
    }
    return entityType.isNotExpandable;
}
