import AsyncIndicator from 'AsyncIndicator';
import breeze from 'breeze-client';
import entitySaveService from 'EntitySaveService';
import ko from 'knockout';
import MessageBus from 'MessageBus';
import ModelProvider from 'ModelProvider';
import { shouldShowDataNotes } from 'NotesHelper';
import NotificationSummary from 'NotificationSummary';

/*! StartNoStringValidationRegion Enum */
export const NotesEventType = {
	Close: 'Close',
	ConfirmClose: 'ConfirmClose',
	LoadFailure: 'LoadFailure'
};
/*! EndNoStringValidationRegion */

export class NotesViewModel {
	constructor(entityKey) {
		this._entity = ko.observable();
		this._entityKey = entityKey;
		this._hasSaved = false;

		this.entity = ko.pureComputed(this._entity);
		this.hasChanges = ko.pureComputed(this._hasChanges, this);
		this.messageBus = new MessageBus();
		this.notificationSummary = new NotificationSummary(this.entity, ko.pureComputed(this._getAllNotes, this));
		this.pageExtensions = { modelProvider: new ModelProvider(null, entityKey.entityType.interfaceName) };
	}

	get hasSaved() {
		return this._hasSaved;
	}

	loadEntityAsync() {
		const entityKey = this._entityKey;
		const entityType = entityKey.entityType;
		const entityManager = breeze.EntityManager.forEntityType(entityType);
		const query = breeze.EntityQuery.fromEntityKey(entityKey);

		return entityManager.executeQuery(query)
			.then((data) => {
				const entity = data.results[0];
				if (entity) {
					this._entity(entity);
				}
				else {
					return this.messageBus.publishAsync(NotesEventType.LoadFailure);
				}
			});
	}

	showDataNotes(note) {
		return shouldShowDataNotes(note);
	}

	saveAsync() {
		return this._runActionAsync(() => {
			return this._saveCoreAsync()
				.tap((isSaved) => {
					if (isSaved) {
						return this.loadEntityAsync();
					}
				});
		});
	}

	saveAndCloseAsync() {
		return this._runActionAsync(() => {
			return this._saveCoreAsync()
				.tap((isSaved) => {
					if (isSaved) {
						return this.messageBus.publishAsync(NotesEventType.Close);
					}
				});
		});
	}

	confirmCloseAsync() {
		return this._runActionAsync(() => {
			return this.messageBus.publishAsync(NotesEventType.ConfirmClose);
		});
	}

	_getAllNotes() {
		return this.entity().Notes();
	}

	_hasChanges() {
		return this.entity().entityAspect.entityManager.observeHasChanges();
	}

	_runActionAsync(callback) {
		const asyncIndicator = new AsyncIndicator();
		asyncIndicator.show();
		return callback().finally(() => asyncIndicator.hideAsync());
	}

	_saveCoreAsync() {
		return entitySaveService.saveWithAlertsAsync(this.entity().entityAspect.entityManager)
			.then(({ isSaved }) => {
				if (isSaved) {
					this._hasSaved = true;
				}

				return isSaved;
			});
	}
}
