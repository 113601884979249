import Promise from 'bluebird';
import { subscribeToRuleDependenciesLegacyDoNotUseAsync } from 'RuleDependencyTracker';
import _ from 'underscore';

const emptySubscription = {
	dispose: _.noop,
};

export default class KnockoutStrategy {
	constructor(context) {
		this._entity = context.entity;
		this._handleChanged = context.handleChanged.bind(context);
		this._rule = context.rule;
		this._disposed = false;
		this._subscriber = undefined;
		this._subscription = undefined;
	}

	dispose() {
		this._disposed = true;
		this._subscription && this._subscription.dispose();
	}

	handleValueChanged() {}

	notifyDependentsAsync() {
		return Promise.resolve();
	}

	performInitialLoad() {
		if (this._subscription || this._disposed) {
			return;
		}

		if (!this._subscriber) {
			this._subscriber = subscribeToRuleDependenciesLegacyDoNotUseAsync(
				this._entity,
				this._rule,
				this._handleChanged,
				{
					observeState: true,
				}
			).then((subscription) => {
				this._subscriber = undefined;
				this._subscription = subscription || emptySubscription;
				if (this._disposed) {
					subscription.dispose();
				}
			});
		}

		return this._subscriber;
	}
}
