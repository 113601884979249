import _ from 'underscore';
import Promise from 'bluebird';
import global from 'Global';
import { isPropertyVertex } from 'PropertyVertex';
import { subscribeToRuleDependenciesLegacyDoNotUseAsync } from 'RuleDependencyTracker';
import RuleService from 'RuleService';
import RuleVertex from 'RuleVertex';
import * as numericService from 'NumericService';
import unitStrategy from 'UnitStrategy';
import errors from 'Errors';

function ProposedValueEngine() {
}

ProposedValueEngine.prototype.applyDefaultValuesAsync = (entity) => {
	const rules = getProposedValueRules(entity);
	if (_.isEmpty(rules)) {
		return Promise.resolve();
	}

	const rulesToApply = getDefaultValueRules(rules);
	const applyRulesPromise = applyRulesAsync(rulesToApply, entity);

	return addOperationPromise(entity, applyRulesPromise);
};

ProposedValueEngine.prototype.createStorage = function (entity) {
	const hasRules = this.hasProposedValueRules(entity.entityType.interfaceName);
	return hasRules ? new ProposedValuesStorage(entity) : this.createEmptyStorage();
};

ProposedValueEngine.prototype.createEmptyStorage = () => {
	return ProposedValuesStorage.empty;
};

ProposedValueEngine.prototype.hasProposedValueRules = (entityName) => {
	const rules = getProposedValueRulesByEntityName(entityName);
	return !_.isEmpty(rules);
};

const ProposedValuesStorage = (() => {
	function ProposedValuesStorage(entity) {
		this._entity = entity;
		this._disposable = null;
		this._refCount = 0;
		this._runningPromises = {};
		this._starter = null;
	}

	ProposedValuesStorage.empty = {
		startListeningAsync: () => Promise.resolve(),
		stopListening: _.noop,
		dispose: _.noop,
		waitForPendingChangesAsync: () => Promise.resolve(),
	};

	ProposedValuesStorage.prototype.startListeningAsync = function () {
		if (this._refCount++ !== 0) {
			return Promise.resolve();
		}

		if (this._starter) {
			return this._starter;
		}

		const entity = this._entity;
		const rules = getProposedValueRules(entity);
		this._starter = subscribeToChangesAsync(entity, rules, this._runningPromises)
			.then((disposable) => {
				if (this._refCount) {
					this._disposable = disposable;
				} else {
					dispose(disposable);
				}
			})
			.finally(() => {
				this._starter = null;
			});

		return this._starter;
	};

	ProposedValuesStorage.prototype.stopListening = function () {
		if (--this._refCount === 0) {
			this.dispose();
		}
	};

	ProposedValuesStorage.prototype.waitForPendingChangesAsync = async function () {
		const promises = Object.values(this._runningPromises)
			.filter((runner) => runner.runningCount > 0)
			.map((runner) => runner.statePromise);

		if (promises.length) {
			await Promise.all(promises);
			await this.waitForPendingChangesAsync();
		}
	};

	ProposedValuesStorage.prototype.dispose = function () {
		dispose(this._disposable);
		this._disposable = null;
	};

	function dispose(disposable) {
		disposable && disposable();
	}

	return ProposedValuesStorage;
})();

function evaluateAsync(entity, rule, runningPromises) {
	return evaluateCoreAsync();

	function evaluateCoreAsync() {
		let runner = runningPromises[rule.property];
		if (!runner) {
			runner = { runningCount: 0, sequence: -1, statePromise: Promise.resolve(), states: [] };
			runningPromises[rule.property] = runner;
		}

		runner.runningCount++;
		const sequence = ++runner.sequence;
		const statePromise = runner.statePromise
			.then(() => addStateAsync(runner.states))
			.finally(() => runner.runningCount--);
		runner.statePromise = statePromise;
		addOperationPromise(entity, statePromise);

		return statePromise
			.tap((state) => {
				if (runner.sequence === sequence) {
					const { states } = runner;
					runner.states = [];
					return processStatesAsync(states)
						.tap(() => {
							if (runner.sequence === sequence) {
								delete runningPromises[rule.property];
							}
						})
						.catch((error) => {
							if (!state.error) {
								state.error = error;
							}
						});
				}
			})
			.tap((state) => {
				if (state.error) {
					throw state.error;
				}
			});
	}

	function addStateAsync(states) {
		return rule
			.processAsync(entity)
			.then((result) => ({ result }))
			.catch((error) => ({ error }))
			.tap((state) => {
				states.push(state);
			});
	}

	function processStatesAsync(states) {
		return Promise.try(() => {
			for (let i = states.length - 1; i >= 0; i--) {
				const { result } = states[i];
				if (
					result &&
					result.isSuccess &&
					isValidResult(entity, rule.property, result.value)
				) {
					return setProposedValueAsync(entity, rule, result.value);
				}
			}
		});
	}
}

function getDefaultValueRules(rules) {
	const result = [];
	for (const propertyName in rules) {
		const rulesForProperty = rules[propertyName];
		for (let i = 0; i < rulesForProperty.length; i++) {
			const rule = rulesForProperty[i];
			if (!rule.hasPathOrSelfDependenciesExcludingConditions()) {
				result.push(rule);
			}
		}
	}

	return result;
}

async function applyRulesAsync(rulesToApply, entity) {
	for (const rule of rulesToApply) {
		try {
			const result = await rule.processAsync(entity);

			if (result.isSuccess && isValidResult(entity, rule.property, result.value)) {
				await setProposedValueAsync(entity, rule, result.value);
			}
		} catch (error) {
			throw new errors.RuleInvocationException(entity.entityType.interfaceName, rule.ruleId, rule.property, error);
		}
	}
}

function getProposedValueRules(entity) {
	return getProposedValueRulesByEntityName(entity.entityType.interfaceName);
}

function getProposedValueRulesByEntityName(entityName) {
	return RuleService.get(entityName).proposedValueRules();
}

function addOperationPromise(entity, promise) {
	if (entity.entityAspect && entity.entityAspect.entityManager) {
		return entity.entityAspect.entityManager.addPromise(promise);
	}
	return promise;
}

function isValidResult(entity, property, result) {
	if (result && !result.IsNoResult) {
		const dataProperty = entity.entityType.getDataProperty(property);
		if (result.Value === null) {
			if (dataProperty && (!dataProperty.isNullable || dataProperty.dataType.name === 'String')) {
				return false;
			}
		}
		else {
			if (dataProperty) {
				const newValue = dataProperty.dataType.parse(result.Value, typeof result.Value);
				if (newValue === null) {
					return false;
				}
			}

			const rules = RuleService.get(entity.entityType.interfaceName);
			const size = rules.numericSize(property);
			const precision = size ? size.precision : null;
			const scale = size ? size.scale : null;
			let allowNegatives = true;

			const measureProperty = entity.entityType.getMeasureProperty(property);
			if (measureProperty) {
				const strategy = unitStrategy.get(measureProperty.unitType);
				if (strategy) {
					allowNegatives = strategy.allowNegatives;
				}
			}

			const dataTypeName = dataProperty ? dataProperty.dataType.name : '';
			const minMax = numericService.getMinMaxValueByType(dataTypeName, allowNegatives, precision, scale);

			if (minMax && (result.Value < minMax.min || result.Value > minMax.max)) {
				return false;
			}
		}
	}

	return result && !result.IsNoResult;
}

function setProposedValueAsync(entity, rule, result) {
	return entity.entityAspect.setValueAsync(rule.property, result.Value);
}

function subscribeToChangesAsync(entity, rules, runningPromises) {
	const fn = global.featureFlags.useDependencyGraph
		? subscribeToChangesWithDependencyGraphAsync
		: subscribeToChangesWithoutDependencyGraphAsync;
	return fn(entity, rules, runningPromises);
}

function subscribeToChangesWithDependencyGraphAsync(entity, rules, runningPromises) {
	return Promise.try(() => {
		const applicableRules = Object.values(rules).flatMap((rulesForProperty) =>
			rulesForProperty.filter((rule) => rule.hasPathDependenciesOtherThanRuleProperty())
		);

		if (!applicableRules.length) {
			return _.noop;
		}

		const { dependencyGraph } = entity.entityAspect.entityManager;
		return Promise.all(applicableRules.map((r) => r.getDependenciesAsync(entity))).then(() => {
			const vertices = applicableRules.map((rule) => {
				const vertex = new ProposedValueVertex(entity, rule, runningPromises);
				vertex.wireDependencies();
				return vertex;
			});

			return () => {
				vertices.forEach((v) => dependencyGraph.removeNode(v));
			};
		});
	});
}

function subscribeToChangesWithoutDependencyGraphAsync(entity, rules, runningPromises) {
	const promises = [];
	const evaluator = (rule) => {
		return () => {
			const { entityState } = entity.entityAspect;
			if (!entityState.isDeleted() && !entityState.isDetached()) {
				evaluateAsync(entity, rule, runningPromises);
			}
		};
	};

	for (const propertyName in rules) {
		const rulesForProperty = rules[propertyName];
		for (let i = 0; i < rulesForProperty.length; i++) {
			const rule = rulesForProperty[i];
			const promise = subscribeToRuleDependenciesLegacyDoNotUseAsync(entity, rule, evaluator(rule), {
				excludedProperty: propertyName,
			});
			promises.push(promise);
		}
	}

	return Promise.all(promises).then((subscriptions) => {
		subscriptions = subscriptions.filter(Boolean);
		return () => {
			subscriptions.forEach((s) => s.dispose());
		};
	});
}

class ProposedValueVertex extends RuleVertex {
	constructor(entity, rule, runningPromises) {
		super(entity, rule);
		this._runningPromises = runningPromises;
	}

	reportChangedAsync(_graph, loadedOnly, source) {
		return Promise.try(() => {
			this.wireDependencies();
			if (!loadedOnly && !isPropertyVertex(source, this.entity, this.rule.property)) {
				return evaluateAsync(this.entity, this.rule, this._runningPromises);
			}
		});
	}
}

export default new ProposedValueEngine();
