import ajaxService from 'AjaxService';
import authConstants from 'AuthenticationServiceConstants';
import captionService from 'CaptionService';
import { ResourceStringsNotLoadedError } from 'ResourceStringsNotLoadedError';
import connection from 'Connection';
import dialogService from 'DialogService';
import errorReportingDialogService from 'ErrorReportingDialogService';
import errorReportingService from 'ErrorReportingService';
import errors from 'Errors';
import fileSaver from 'FileSaver';
import global from 'Global';
import { loadPreviewModeErrorHandlerAsync } from 'ModuleLoader';
import nativeBridge from 'NativeBridge';
import navigationService from 'NavigationService';
import notificationType from 'NotificationType';
import { strategySelectionErrorHandler } from 'PersistentStorage';
import { ApplicationProblemType } from 'ProblemType';
import { RepeatedOccurrencesErrorHandlerFactory } from 'RepeatedOccurrencesErrorHandlerFactory';
import sessionExpirationService from 'SessionExpirationService';
import { htmlEscape, tryParseJsonWithStatus } from 'StringUtils';
import toastService from 'ToastService';
import versionChecker from 'VersionChecker';
import Promise from 'bluebird';
import errorStackParser from 'error-stack-parser';
import Vue from 'vue';

function fetchServiceWorkerScriptAsync() {
	const url = `${global.rootPathForModule}${global.formFactorPath}/sw.js`;
	return ajaxService.getAsync(url);
}

const httpError = {
	noResponse: 0,
	unauthorized: 401,
	forbidden: 403,
	notFound: 404,
	requestTimeout: 408,
	conflict: 409,
	badGateway: 502,
};

function setupGlobalEventHandlers() {
	setupWindowOnErrorhandler();
	setupWindowUnhandledRejection();
	setupVueErrorHandler();

	/*! SuppressStringValidation Developer error message */
	strategySelectionErrorHandler((error) => reportError(error, 'Error while selecting persistent storage strategy.'));
}

function setupWindowOnErrorhandler() {
	window.onerror = (message, url, line, column, error) => {
		if (!error) {
			error = { message };
		}

		error = new errors.UnhandledError(error);
		/*! StartNoStringValidationRegion (No captions here) */
		error.getData = () => {
			return [
				{ name: 'UnhandledErrorSource', value: `${url}:${line}:${column}` }
			];
		};
		/*! EndNoStringValidationRegion */

		handleError(error);
		return !global.isDebugMode;
	};
}

function handlePreviewErrorAsync(error) {
	return Promise.resolve(global.isPreviewMode() && loadPreviewModeErrorHandlerAsync()).then((handler) => {
		return handler && handler.handleAsync(error);
	});
}

function handleVersionErrorAsync() {
	return versionChecker.handleVersionsMismatchAsync();
}

function handleFormFlowErrorsAsync(error, isSilent) {
	if (error instanceof errors.FormFlowSerializationError) {
		if (isSilent) {
			return true;
		}

		showOKDialogAsync(
			notificationType.Error,
			error.message,
			captionService.getString('3f824edf-f958-48d8-97bf-c2bd1eb831dd', 'Form-Flow Serialization Error'));
		return true;
	}
}

function handleExternalError(error, isSilent) {
	if (error.message === 'Script error.' && !error.stack) {
		return true;
	}

	if (!error.stack || nativeBridge.isOpen()) {
		// Errors with no stack could potentially be from an external source but we need examples to analyze.
		return false;
	}

	const handleExternalErrorWithDialog = () => {
		if (isSilent) {
			return true;
		}

		showOKDialogAsync(
			notificationType.Error,
			stubCaptionService.getString(
				'27895d35-647e-4b80-ad63-fd79cd70c049',
				'An error occurred due to a 3rd party browser extension. Please disable 3rd party extensions and try again.\r\n\r\nIf the issue persists, please contact your Administrator.'
			),
			stubCaptionService.getString(
				'98bb4a34-39d6-4750-b5bd-2e8c5c959f33',
				'Browser Extension Error'
			)
		);
		return true;
	};

	// NoScript Extension
	if (
		error instanceof ReferenceError &&
		error.message.includes('importScripts') &&
		error.fileName &&
		error.fileName.includes('injectedScript')
	) {
		return handleExternalErrorWithDialog();
	}

	const stackFrames = errorStackParser.parse(error);
	const isExternalError = stackFrames.some((stackFrame) => {
		if (typeof stackFrame.lineNumber === 'undefined') {
			return false;
		}

		const isInlineScript =
			(!stackFrame.fileName && /^\s*at <anonymous>:/.test(stackFrame.source)) ||
			(stackFrame.lineNumber === 0 &&
				!stackFrame.functionName &&
				stackFrame.fileName.trim() === 'at') ||
			stackFrame.fileName === global.windowLocation.href ||
			/\.<anonymous>\s*$/.test(stackFrame.fileName);
		if (isInlineScript) {
			return true;
		}

		const isExtensionScript = /^.+-extension:\/\//.test(stackFrame.fileName);
		return isExtensionScript;
	});

	if (isExternalError) {
		return handleExternalErrorWithDialog();
	}

	return false;
}

function handleDocumentNotFoundAsync(error) {
	if (error instanceof errors.DocumentNotFoundOrNotApplicable) {
		/*! SuppressStringValidation Developer error message */
		errorReportingService.sendErrorReport(error, 'Document not found or not applicable.');
		showOKDialogAsync(
			notificationType.Error,
			captionService.getString(
				'02419c9f-8366-4fb9-869f-d9191a1b87c3',
				'Selected document cannot be found or is not applicable in the current context. This issue has been reported to us and will be addressed by the relevant team.'
			),
			captionService.getString('8295db84-e784-4bbe-90e8-b657e2f0b2c3', 'Document Error')
		);
		return true;
	}
}

function handleResizeObserverLimitError(error) {
	return error.message === 'ResizeObserver loop limit exceeded';
}

function isTransientError(errorCode) {
	return (errorCode >= 500 && errorCode < 600);
}

function isServerOrTransientError(errorCode) {
	return errorCode === httpError.notFound || errorCode === httpError.requestTimeout || isTransientError(errorCode);
}

function setupWindowUnhandledRejection() {
	window.addEventListener('unhandledrejection', (e) => {
		if (!global.isDebugMode) {
			e.preventDefault();
		}

		const detail = e.detail || e; // Native promise errors have reason on the event instead of a detail property.
		let error = detail.reason;
		if (!isErrorLike(error)) {
			error = createGenericUnhandledRejectionError(detail);
		}

		handleError(error);
	});
}

function createGenericUnhandledRejectionError(detail) {
	let data;
	try {
		data = JSON.stringify(detail.reason);
	}
	catch (_) {
		/*! SuppressStringValidation Error message */
		data = '(unserializable value)';
	}

	/*! SuppressStringValidation Error message */
	const error = { message: 'Unhandled Promise rejection.' };
	/*! SuppressStringValidation Error message */
	error.getData = () => [{ name: 'detail.reason', value: data }];

	return error;
}

function setupVueErrorHandler() {
	Vue.config.errorHandler = (error, vm, info) => {
		handleError(error);
		if (global.isDebugMode) {
			console.error('Error occurred in Vue:', info, vm, error); // eslint-disable-line no-console
		}
	};
}

const errorHandlers = [
	handleResizeObserverLimitError,
	new RepeatedOccurrencesErrorHandlerFactory(
		(error) => error?.message === 'ResizeObserver loop completed with undelivered notifications.', 5, 5).getHandler(),
	function handleCancellationError(error) {
		return error instanceof Promise.CancellationError || error instanceof errors.CancellationError;
	},
	function handleRenewSessionError(error) {
		if (error instanceof errors.RenewSessionError) {
			navigationService.post('#/logOff', {
				reason: authConstants.AuthenticationResultStringTable[error.authenticationResult],
			});
			return true;
		}
	},
	function handleSessionOutdatedError(error) {
		if (error instanceof errors.SessionOutdatedError) {
			sessionExpirationService.renewSessionAsync()
				.then(() => {
					const reloadText = captionService.getString('81a37f8a-6691-4680-917a-518cc08aafde', 'Reload');
					const sessionText = captionService.getString('e3bd3751-57fb-4617-8a91-8c9ee121072a', 'Your session data has been updated.');
					return dialogService.showDialogAsync({
						title: reloadText,
						body: captionService.getString('41545634-4f24-4b8a-83c2-20fd1ca2287e', '{0} Please reload this page in order to continue working.', sessionText),
						notificationType: notificationType.Information,
						buttonOptions: [
							{
								bindingString: 'click: reload',
								caption: reloadText,
								isPrimary: true,
								isDefault: true
							}
						],
						preHideCallback(e) {
							e.preventDefault();
						},
						viewModel: {
							reload: () => global.windowLocation.reload()
						}
					});
				});
			return true;
		}
	},
	function handleForbiddenError(error, isSilent) {
		if (error.status === httpError.forbidden || error instanceof errors.UnavailableArgumentsOrSecurityError) {
			if (isSilent) {
				return true;
			}

			let message;
			const title = captionService.getString(
				'54d69684-d59f-4f68-9574-5c78dde5b734',
				'Unable to Perform Operation'
			);

			const forbiddenByProxyError = getForbiddenByProxyError(error);
			if (forbiddenByProxyError) {
				message = getForbiddenByProxyMessage();
				showDownloadErrorDetailsDialogAsync(message, title, () => {
					return new Blob(
						[
							JSON.stringify({
								requestUrl: forbiddenByProxyError.url,
								responseHeaders: forbiddenByProxyError.getAllResponseHeaders(),
								responseBody: forbiddenByProxyError.responseText,
							}),
						],
						{ type: 'application/json' }
					);
				});
			}
			else {
				const dataServiceRequestError = errors.findError(error, errors.DataServiceRequestError);
				if (dataServiceRequestError) {
					message = dataServiceRequestError.innerMessage;
				}
				message = message || captionService.getString(
					'e83c0c3d-d172-492a-9064-a86faf16e4af',
					'You do not have permission to perform this action. Contact your system administrator to be granted appropriate permissions.'
				);
				showOKDialogAsync(notificationType.Error, message, title);
			}

			return true;
		}
	},
	function handleConflictError(error, isSilent) {
		if (error instanceof errors.DataServiceRequestError && error.status === httpError.conflict) {
			if (isSilent) {
				return true;
			}

			showOKDialogAsync(
				notificationType.Warning,
				error.friendlyMessage(),
				captionService.getString(
					'54d69684-d59f-4f68-9574-5c78dde5b734',
					'Unable to Perform Operation'
				)
			);

			return true;
		}
	},
	function handleNoResponseError(error, isSilent) {
		if (error.status === httpError.noResponse) {
			if (isSilent) {
				return true;
			}

			const { title, message } = getNoResponseErrorCaption();
			showOKDialogAsync(notificationType.Error, message, title);

			return true;
		}
	},
	handlePreviewErrorAsync,
	handleFormFlowErrorsAsync,
	function handleNetworkError(error, isSilent) {
		if (isNetworkErrorType(error) && isServerOrTransientError(error.status)) {
			if (isSilent) {
				return true;
			}

			const { title, message } = getNetworkErrorCaption(error);
			showOKDialogAsync(notificationType.Error, message, title, {
				dialogID: 'ConnectionError',
			});

			return true;
		}
	},
	function handleNetworkJsonError(error, isSilent) {
		if (isNetworkJsonError(error)) {
			if (!isSilent) {
				const { title, message } = getNetworkJsonErrorCaption();
				showOKDialogAsync(notificationType.Error, message, title, {
					dialogID: 'ConnectionError',
				});
			}

			return true;
		}
	},
	function handleDatabaseMismatchError(error) {
		if (error.name === networkErrorTypes.AjaxError && error.status === httpError.unauthorized) {
			/*! SuppressStringValidation Response Header Name */
			const header = error.getResponseHeader('content-type');

			if (header && header.includes('application/problem+json')) {
				const jsonResult = tryParseJsonWithStatus(error.responseText);
				if (jsonResult.parsed && jsonResult.json.type === ApplicationProblemType.DatabaseMismatchError) {
					showOKDialogAsync(
						notificationType.Error,
						captionService.getString(
							'8787803b-efed-49bf-a60c-d37a9e65ea4e',
							'The Database configured for GLOW services is different from that configured for the service requested. Please contact your Administrator.'
						),
						captionService.getString(
							'bf203403-dd14-4a28-8d3b-88afd8b64c6f',
							'Database Mismatch'
						),
						{ dialogID: 'DBMismatchError' }
					);

					return true;
				}
			}
		}
	},
	function handleOfflineError(error, isSilent) {
		if (error instanceof connection.OfflineError) {
			if (isSilent) {
				return true;
			}

			if (!global.isInteractive) {
				handleOfflineErrorWhenNotInteractive();
			} else {
				showOKDialogAsync(
					notificationType.Error,
					captionService.getString(
						'd7bc5a75-5db1-4eef-acbd-16659e64a7b6',
						'Your Internet connection appears to be offline. Please try again later.'
					),
					captionService.getString(
						'ea694989-6a76-48ba-ace2-142de928504b',
						'Connection Lost'
					),
					{ dialogID: 'ConnectionLostError' }
				);
			}

			return true;
		}
	},
	function handleScriptLoadError(error, isSilent) {
		if (error instanceof errors.ScriptLoadError) {
			if (error.errorType === errors.ScriptLoadErrorType.Timeout) {
				return handleScriptLoadErrorCore(isSilent, captionService.getString(
					'8cca6b8c-a875-421f-bf9c-92f8b51b60c4',
					'The server took too long to load a script. Please refresh the page and try again or contact your Administrator if the error persists.'));
			}
			else {
				const handlingError = error.status === httpError.notFound ? handleVersionErrorAsync() : Promise.resolve(false);
				return handlingError.then((isHandled) => {
					if (!isHandled) {
						isHandled = handleScriptLoadErrorCore(isSilent, captionService.getString(
							'7082df76-5c5f-4a7e-a0e8-6893771bf3c0',
							'The server encountered an error while loading a script. Please refresh the page and try again or contact your Administrator if the error persists.'));
					}

					return isHandled;
				});
			}
		}
	},
	function handleLowDiskSpaceError(error, isSilent) {
		if (error instanceof errors.LowDiskSpaceError) {
			if (!isSilent) {
				const isStickyToast = true;
				toastService.showToastAlertAsync(
					captionService.getString('fd3cb529-533d-4e4b-901f-016d374292d2', 'Your device\'s local storage is running low, application performance may be affected. Please free up space and refresh the page to return to full functionality.'),
					notificationType.Error,
					isStickyToast);
			}
			return true;
		}
	},
	function handleServiceWorkerInstallationError(error, isSilent) {
		if (error instanceof errors.ServiceWorkerInstallationError) {
			if (!isSilent) {
				const isStickyToast = true;
				toastService.showToastAlertAsync(
					error.friendlyMessage(),
					notificationType.Error,
					isStickyToast);
			}
			return true;
		}
	},
	handleVersionErrorAsync,
	handleExternalError,
	handleDocumentNotFoundAsync,
	function handleServiceWorkerInstallationBadResponseError(error, isSilent) {
		if (error instanceof errors.ServiceWorkerInstallationBadResponseError) {
			if (!isSilent) {
				return handleServiceWorkerInstallationResponseStatusCode(error.swResponseStatus);
			}
			return true;
		}
	},
	async function handleServiceWorkerUnknownInstallationError(error, isSilent) {
		if (error instanceof errors.ServiceWorkerUnknownInstallationError) {
			if (isSilent) {
				return true;
			}

			try {
				await fetchServiceWorkerScriptAsync();
				const isStickyToast = true;
				toastService.showToastAlertAsync(
					captionService.getString(
						'220a3b1a-1c95-474a-b3f0-77e78848be3a',
						'A service worker failed to install as expected. A 3rd party extension in your browser could be blocking the request. Please disable 3rd party extensions and try again or contact your Administrator if the error persists.'),
					notificationType.Error,
					isStickyToast);
				return true;
			}
			catch (error) {
				if (error.name === networkErrorTypes.AjaxError) {
					return handleServiceWorkerInstallationResponseStatusCode(error.status);
				}
			}
		}
	},
	function handleStreamQueryError(error, isSilent) {
		if (error instanceof errors.StreamQueryError) {
			if (!isSilent) {
				dialogService.showDialogAsync({
					title: getGenericTitle(),
					body: captionService.getString('473530d1-a42f-461b-aa57-4b36f7580e4b', 'The server encountered an error while processing your request. Please try again or contact your Administrator if the error persists.'),
					notificationType: notificationType.Error
				});
			}
			return true;
		}
	}
];

function reportError(error, userErrorDescription, isSilent = true) {
	if (!isErrorLike(error)) {
		throw new Error('reportError can only be called with an Error object.');
	}

	handleError(error, { isSilent, userErrorDescription });
}

function isErrorLike(obj) {
	return (obj instanceof Error) || !!(obj && obj.message && obj.stack);
}

const handledErrors = new WeakSet();

function handleError(error, { isSilent, userErrorDescription } = {}) {
	if (error && typeof error === 'object') {
		if (handledErrors.has(error)) {
			return;
		}

		handledErrors.add(error);
	}

	Promise
		.first(errorHandlers, (handler) => {
			let currentError = error;
			const errorList = [];
			while (currentError) {
				errorList.push(currentError);
				currentError = currentError.innerError;
			}

			return Promise.first(errorList, (error) => {
				return Promise.resolve(handler(error, isSilent));
			});
		})
		.then((isErrorHandled) => {
			if (!isErrorHandled) {
				isSilent ?
					errorReportingService.sendErrorReport(error, userErrorDescription) :
					errorReportingDialogService.showErrorDialog(error);
			}
		});
}

function handleOfflineErrorWhenNotInteractive() {
	/*! StartNoStringValidationRegion (No captions here) */
	dialogService.showDialogAsync({
		notificationType: notificationType.Error,
		body: stubCaptionService.getString(
			'197fd756-68a9-4dd8-9976-ebf529836c81',
			'Please wait for connection to be established.'
		),
		title: stubCaptionService.getString(
			'601cf17a-5226-452e-9d42-74b0b46d2757',
			'Waiting for Connection'
		),
		footer: '&nbsp;',
		dialogID: 'ConnectionReestablishmentError',
		closeOnDismissOnly: true,
	});
	/*! EndNoStringValidationRegion */
	connection.subscribe((isOnline) => {
		if (isOnline) {
			global.windowLocation.reload();
		}
	});
}

const reportBootstrapError = (() => {
	/*! StartNoStringValidationRegion Cannot translate messages during bootstrap process */
	const bootstrapErrorHandlers = [
		function handleBootstrapForbiddenError(error) {
			if (error.status === httpError.forbidden && isForbiddenByProxy(error)) {
				showBootstrapErrorDialog(
					'Connection Blocked',
					getForbiddenByProxyMessage()
				);
				return true;
			}
		},
		function handleBootstrapNoResponseError(error) {
			if (error.status === httpError.noResponse) {
				const { title, message } = getNoResponseErrorCaption();
				showBootstrapErrorDialog(title, message);
				return true;
			}
		},
		function handleBootstrapNetworkError(error) {
			if (isNetworkErrorType(error) && isServerOrTransientError(error.status)) {
				const { title, message } = getNetworkErrorCaption(error);
				showBootstrapErrorDialog(title, message);
				return true;
			}
		},
		function handleBootstrapNetworkJsonError(error) {
			if (isNetworkJsonError(error)) {
				const { title, message } = getNetworkJsonErrorCaption();
				showBootstrapErrorDialog(title, message);
				return true;
			}
		},
		function handleBootstrapOfflineError(error) {
			if (error instanceof connection.OfflineError) {
				handleOfflineErrorWhenNotInteractive();
				return true;
			}
		},
	];

	return function reportBootstrapError(error) {
		errorHandler.reportError(error);

		for (const handler of bootstrapErrorHandlers) {
			let current = error;
			while (current) {
				if (handler(current)) {
					return;
				}
				current = current.innerError;
			}
		}

		showBootstrapErrorDialog(
			'Startup Error',
			'Could not start the application due to an unexpected error. Please refresh the page and try again or contact your Administrator if the error persists.'
		);
	};

	function showBootstrapErrorDialog(title, message) {
		dialogService.showAsync(
			notificationType.Error,
			message,
			title,
			[
				{
					caption: 'Refresh',
					isPrimary: true,
					result() {
						global.windowLocation.reload();
					},
				},
			],
			{
				closeOnDismissOnly: true,
			}
		);
	}
	/*! EndNoStringValidationRegion */
})();

function handleScriptLoadErrorCore(isSilent, message) {
	if (isSilent) {
		return true;
	}

	showOKDialogAsync(
		notificationType.Error,
		message,
		captionService.getString('6dbdcf5c-b705-403f-a0f5-887bdbb16f05', 'Error Loading Script')
	);

	return true;
}

function handleServiceWorkerInstallationResponseStatusCode(statusCode) {
	if (statusCode === 0) {
		// Service worker script cannot be retrieved because requests are being blocked client-side (by a browser extension for example)
		const isStickyToast = true;
		toastService.showToastAlertAsync(
			captionService.getString('27b7d795-3bbe-435b-b82d-21f2b3d89ee3', 'A service worker failed to install as expected. A 3rd party browser extension could be blocking the request. Please disable 3rd party extensions and try again or contact your Administrator if the error persists.'),
			notificationType.Error,
			isStickyToast);
		return true;
	} else if (statusCode === 300) {
		const isStickyToast = true;
		toastService.showToastAlertAsync(
			captionService.getString('8748fb6e-2cad-44e7-ab2b-6fc681be718c', 'A service worker failed to install as expected. The requested module was ambiguous and as such some functionality may not work as intended if working in offline mode. Please refresh the page and try again or contact your Administrator if the error persists.'),
			notificationType.Error,
			isStickyToast);
		return true;
	} else if (statusCode === 401 || statusCode === 403) {
		const isStickyToast = true;
		toastService.showToastAlertAsync(
			captionService.getString('58c917b2-ac50-48d8-b4b7-b8497c1bce40', 'A service worker failed to install as expected. You don\'t have access to the requested module. Please refresh the page and try again or contact your Administrator if the error persists.'),
			notificationType.Error,
			isStickyToast);
		return true;
	} else if (isTransientError(statusCode)) {
		// Service worker script cannot be retrieved because there is a 5XX error (non-client side)
		const isStickyToast = true;
		toastService.showToastAlertAsync(
			captionService.getString('681590a6-c0e1-4fde-ac49-b35cd558f627', 'A service worker failed to install as expected. Some functionality may not work as intended if working in offline mode. Please refresh the page and try again or contact your Administrator if the error persists.'),
			notificationType.Error,
			isStickyToast);
		return true;
	} else {
		// Service worker script cannot be retrieved because there is a 4XX error (the path is wrong for example)
		return false;
	}
}

const networkErrorTypes = {
	AjaxError: 'AjaxError',
	BreezeQueryError: 'BreezeQueryError',
	BreezeMetadataError: 'BreezeMetadataError',
	DataServiceRequestError: 'DataServiceRequestError',
	ODataSaveError: 'ODataSaveError',
};

function isNetworkErrorType(error) {
	return Object.prototype.hasOwnProperty.call(networkErrorTypes, error.name);
}

function isForbiddenByProxy(error) {
	return !!getForbiddenByProxyError(error);
}

function getForbiddenByProxyError(error) {
	const ajaxError = errors.matchError(error, (e) => e.name === networkErrorTypes.AjaxError);
	/*! SuppressStringValidation Not a caption */
	return ajaxError && !ajaxError.getResponseHeader('wtg-app') ? ajaxError : undefined;
}

function getForbiddenByProxyMessage() {
	return stubCaptionService.getString(
		'89caba78-23f4-4b6f-9372-f015a46826f3',
		'We were unable to complete the last action because it was blocked by the server. This may be due to a security policy of a firewall or web proxy. Please contact your system administrator.'
	);
}

function getNoResponseErrorCaption() {
	return {
		title: stubCaptionService.getString('ca5434e6-37c8-4eb5-b028-9f9f353d2a2f', 'Action Failed'),
		message: stubCaptionService.getString(
			'602876da-7230-402d-be3e-cb675ef2f7b8',
			'We were unable to complete the last action, which could be due to a few reasons:\r\n1. An error occurred when trying to connect to the server. Please refresh the page and try again.\r\n2. A 3rd party browser extension has blocked the request. Please disable 3rd party extensions and try again.\r\n\r\nIf the issue persists, please contact your Administrator.'
		),
	};
}

function getNetworkErrorCaption(error) {
	let title, message;

	if (error.status === httpError.badGateway) {
		message = stubCaptionService.getString(
			'826959d6-d4ce-4203-96ef-8756eb45f5bf',
			'We were unable to complete the last action, which could be due to a few reasons:\r\n1. An error occurred when trying to connect to the server.\r\n2. A firewall or web proxy blocked or failed to process the request.\r\n\r\nPlease refresh the page and try again. If the issue persists, please contact your Administrator.'
		);
	} else if (errors.isDbUpgradeError(error)) {
		title = stubCaptionService.getString(
			'0f6bad8c-bb3c-4cee-924e-6b2431548c09',
			'Database and Application Upgrading'
		);
		message = stubCaptionService.getString(
			'bb4e7b12-0c9b-43d0-92ab-9146bd18a5b4',
			'The portal cannot load until the database and application upgrade has completed. Please try again in a few minutes or contact support if this error persists.'
		);
	} else {
		message = stubCaptionService.getString(
			'f95af9a6-4e27-42f7-ae59-f8bc1a5b00b3',
			'There was an unexpected error. Please try again or contact your Administrator if the error persists.'
		);
	}

	if (!title) {
		title = getGenericTitle();
	}

	return { title, message };
}

function getGenericTitle() {
	return stubCaptionService.getString('ffadadbf-c25a-479d-aff2-17704b72bf12', 'Something Went Wrong');
}

function isNetworkJsonError(error) {
	return (
		error instanceof ajaxService.AjaxError &&
		error.status === 200 &&
		error.errorStatusText === 'parsererror' &&
		/*! SuppressStringValidation Header name */
		error.getResponseHeader('content-type').includes('application/json') &&
		error.innerError instanceof SyntaxError
	);
}

function getNetworkJsonErrorCaption() {
	return {
		title: getGenericTitle(),
		message: stubCaptionService.getString(
			'a56e4dea-0128-4464-83f0-b8869e2e9f63',
			'An unexpected error occurred while loading data. This could be due to a temporary network or server issue. Please try again or contact your Administrator if the error persists.'
		),
	};
}

function showOKDialogAsync(messageNotificationType, message, title, options) {
	return dialogService.alertAsync(
		messageNotificationType,
		message,
		title,
		Object.assign({ keepDialog: true }, options)
	);
}

function showDownloadErrorDetailsDialogAsync(message, title, detailBlob) {
	const buttonOptions = [
		{
			caption: dialogService.buttonTypes.Ok().text,
			result: dialogService.buttonTypes.Ok().value,
			isDismiss: true,
			isPrimary: true,
		},
	];
	/*! SuppressStringValidation file name */
	const viewModel = {
		downloadErrorDetails: () => fileSaver.saveAs(detailBlob(), 'ErrorDetails.json'),
	};
	const options = { keepDialog: true, bodyAllowHtml: true };
	return dialogService.showAsync(
		notificationType.Error,
		`${message}<div style="margin-top: 1em"><a href="#" data-bind="click: downloadErrorDetails">${htmlEscape(
			captionService.getString(
				'fd0fe679-ba9e-4806-9c69-3cdea4d5749b',
				'Download error details for system administrator'
			)
		)}</a></div>`,
		title,
		buttonOptions,
		options,
		viewModel
	);
}

const stubCaptionService = {
	getString(key, fallbackText) {
		try {
			return captionService.getString(key, fallbackText);
		}
		catch (e) {
			if (e instanceof ResourceStringsNotLoadedError) {
				return fallbackText;
			}
			throw e;
		}
	}
};

const errorHandler = { setupGlobalEventHandlers, reportError, reportBootstrapError };
export default errorHandler;
