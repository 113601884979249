/*! StartNoStringValidationRegion Does not contain captions */
import global from "Global";
import { isGuid } from "StringUtils";

export const indexRoute = "Index";

export enum ODataType {
  Guid = "Guid",
}

export function getODataPrefix(): string {
  return "odata/";
}

export function getODataRoute(routeName: string): string {
  return global.serviceUri + getODataPrefix() + routeName;
}

export function oDataValue(value: string | null | undefined, type?: ODataType): string | null | undefined {
  let result;

  if (type === ODataType.Guid) {
    result = isGuid(value) ? value : null;
  } else {
    const escaped = (value ?? "").replace(/'/g, "''");
    result = `'${escaped}'`;
  }

  return result;
}

export function addODataUriFilter(baseUri: string, filter: string): string {
  if (filter) {
    return `${baseUri}?$filter=${encodeURIComponent(filter)}`;
  }
  return baseUri;
}

export function getODataParamUri(data: object): string {
  const resourcePathArray: string[] = [];
  const queryStringArray: string[] = [];
  Object.entries(data).forEach(([key, value]) => {
    resourcePathArray.push(`${key}=@${key}`);
    queryStringArray.push(`@${key}=${encodeURIComponent(value)}`);
  });

  return `(${resourcePathArray.join(",")})?${queryStringArray.join("&")}`;
}

export function addODataUriParams(baseUri: string, data: object): string {
  return baseUri + getODataParamUri(data);
}

export function addODataUriGuidKey(baseUri: string, guidKey: string): string {
  return `${baseUri}(${guidKey})`;
}

export function addODataETagHeader(
  headers: Record<string, unknown>,
  entity: Record<string, Record<string, unknown>>
): void {
  if (entity["@odata.etag"]) {
    headers["If-Match"] = entity["@odata.etag"];
  }
}

export enum MetadataType {
  Verbose = "Verbose",
  Minimal = "Minimal",
  None = "None",
}

export function getODataHeaders(metadataType: MetadataType, headers?: Record<string, string>): Record<string, string> {
  const result: Record<string, string> = {};

  result["OData-Version"] = "4.0";
  result.Prefer = 'odata.include-annotations="*"';

  if (metadataType === MetadataType.Verbose) {
    result["Content-Type"] = "application/json;odata.metadata=minimal";
  } else if (metadataType === MetadataType.Minimal) {
    result.Accept = "application/json;odata.metadata=minimal";
  } else if (metadataType === MetadataType.None) {
    result.Accept = "application/json;odata.metadata=none";
  }

  return Object.assign(result, headers);
}

export function getODataErrorMessage(errorBody: ErrorObject | undefined): string | undefined {
  if (errorBody) {
    return (errorBody.error?.message ?? errorBody.Message) as string | undefined;
  }

  return undefined;
}

export function isODataValidationError(errorBody: ErrorObject | undefined): boolean {
  return (errorBody?.ErrorCode as string | undefined) === "ODataValidation";
}

export function trimEndNormalSpace(str: string): string {
  return str.replace(/(\x20+)$/g, "");
}

export interface ErrorObject {
  [key: string]: ErrorObject | undefined;
}
/*! EndNoStringValidationRegion */
