import global from 'Global';
import $ from 'jquery';
import ko from 'knockout';
import constants from 'Constants';
import { loadTemplateAsync } from 'ModuleLoader';
import dialogService from 'DialogService';
import vueFactory from 'VueFactory';
import MessageBus from 'MessageBus';

export default class MaterialDesignVueDialogService {
	constructor() {
		this.states = [];
	}

	canShowDialog() {
		return global.materialDesign && !dialogService.isDialogOpen();
	}

	async showDialogAsync(dataItem, state, template, viewModel, title, captions) {
		const pageExtensions = {
			dataItem,
			messageBus: new MessageBus(),
		};
		this.hideDialogVue(state);

		const shell = await loadTemplateAsync(template.Shell);
		const bindingContext = new ko.bindingContext(viewModel);
		bindingContext.$contentViewModel = viewModel;
		if (viewModel) {
			viewModel.pageExtensions = pageExtensions;
		}
		const $template = $('<div>');
		$(shell).appendTo($template);
		$template.appendTo($(constants.CssClasses.ModalsContainer.Selector));
		state.data = {
			captions,
			viewModel,
			showDialog: false,
			title,
		};
		const instance = await vueFactory.createVueInstanceAsync({
			contentContainer: $template[0],
			validationRegistrar: pageExtensions && pageExtensions.validationRegistrar,
			knockoutContext: bindingContext,
			bindingContext: viewModel,
			requiresParent: true,
			name: 'VueDialogContext',
			data: () => state.data,
			methods: {
				onInput: () => {
					this.hideDialogVue(state);
				},
			},
		});
		state.data.showDialog = true;
		state.element = instance.$el;
		this.states.push(state);
		return [$(state.element), bindingContext];
	}

	hideDialogVue(state) {
		this.removeState(state);
		hideDialogVueCore(state);
	}

	removeState(state) {
		const index = this.states.indexOf(state);
		if (index >= 0) {
			this.states.splice(index, 1);
		}
	}

	hideAllDialogs() {
		for(const state of this.states) {
			hideDialogVueCore(state);
		}

		this.states = [];
	}
}

function hideDialogVueCore(state) {
	if (state.data) {
		state.data.showDialog = false;
		state.deferred && state.deferred.resolve();
		setTimeout(() => {
			if (state.element) {
				ko.removeNode(state.element);
				state.element = null;
			}
		}, 500);
	}
}
