// TODO:
// - Add support for configuration mode and dialogs. FormFlowInvoker to specify the required info.

import captionService from "CaptionService";
import { CssClass, PageType } from "Constants";
import dialogService, { type Dialog, type DialogOptions } from "DialogService";
import FavoritesViewModel from "FavoritesViewModel";
import { FormFactor } from "FormFactors";
import { type ShowFormActivity } from "FormFlowActivityInvokers/ShowForm.ts";
import { type FormFlowSession } from "FormFlowSession";
import type { ShellDisposeFn, ShellProviderFn } from "FormFlowTypes";
import { type FormTemplate } from "FormTemplateService";
import global from "Global";
import helpPresenter from "HelpPresenter";
import { type MenuItem } from "MenuItem";
import menuItemsProvider, { type MenuItemOptions } from "MenuItemsProvider";
import { loadTemplateAsync } from "ModuleLoader";
import navigationService from "NavigationService";
import { type BasePageViewModel } from "PageViewModel";
import type PinnableMenuItem from "PinnableMenuItem";
import TaskPresenter, { type TaskShellOptions } from "TaskPresenter";
import type TaskViewModel from "TaskViewModel";
import { getLoadingCaption, observeTitle } from "TitleService";
import uiService, { type Shell, type ShellOptions } from "UIService";
import vueFactory from "VueFactory";
import widgetFactory from "WidgetFactory";
import ko, { type BindingContext, type Computed } from "knockout";
import type Vue from "vue";

export type FormProviderFn = (formId: string) => Promise<FormTemplate>;

export interface DialogState {
  data?: {
    showDialog: boolean;
    title?: string | Computed<string>;
  };
  dialog?: Dialog;
  element?: Element;
}

export interface ShellProviderFactory {
  buildPageShellProvider(formProviderFn: FormProviderFn): [ShellProviderFn, ShellDisposeFn];
  buildDialogShellProvider(formProviderFn: FormProviderFn, dialogOptions: DialogOptions): [ShellProviderFn, () => void];
}

export class FormFlowUIContextProvider {
  constructor(protected readonly shellProviderFactory: ShellProviderFactory) {}

  createPageContext(formProviderFn: FormProviderFn, options: TaskShellOptions): PageContext {
    const [shellProviderFn, disposeFn] = this.shellProviderFactory.buildPageShellProvider(formProviderFn);
    return new PageContext(new TaskPresenter(shellProviderFn, disposeFn, options));
  }

  createDialogContext(formProviderFn: FormProviderFn, options: TaskShellOptions): Context {
    const [shellProviderFn, disposeFn] = this.shellProviderFactory.buildDialogShellProvider(
      formProviderFn,
      options.dialogOptions
    );

    return new Context(new TaskPresenter(shellProviderFn, disposeFn, options));
  }
}

const defaultShellProviderFactory: ShellProviderFactory = {
  buildPageShellProvider(formProviderFn: FormProviderFn): [ShellProviderFn, () => void] {
    return [
      getPageShellAsync.bind(null, formProviderFn),
      (): void => {
        /* no op */
      },
    ];
  },

  buildDialogShellProvider(
    formProviderFn: FormProviderFn,
    dialogOptions: DialogOptions
  ): [ShellProviderFn, () => void] {
    const state: DialogState = {};
    return global.materialDesign
      ? [getDialogShellVueAsync.bind(null, state, formProviderFn, dialogOptions), hideDialogVue.bind(null, state)]
      : [getDialogShellAsync.bind(null, state, formProviderFn, dialogOptions), hideDialog.bind(null, state)];
  },
};

// Consider removing this wrapper, and simplifying tests?
export class Context {
  private taskPresenter: TaskPresenter;
  constructor(taskPresenter: TaskPresenter) {
    this.taskPresenter = taskPresenter;
  }

  isBusy(): boolean {
    return this.taskPresenter.isBusy();
  }

  abort(): void {
    this.taskPresenter.abort();
  }

  dispose(): void {
    return this.taskPresenter.dispose();
  }

  async showFormAsync(session: FormFlowSession, activity: ShowFormActivity): Promise<void> {
    await this.taskPresenter.showFormAsync(session, activity);
  }

  resolveTransition(): void {
    this.taskPresenter.resolveTransition();
  }

  hasFormEntity(): boolean {
    return this.taskPresenter.hasFormEntity();
  }

  async ensureSavedAsync(): Promise<boolean> {
    return await this.taskPresenter.ensureSavedAsync();
  }

  get session(): FormFlowSession {
    return this.taskPresenter.session;
  }
}

export class PageContext extends Context {
  private _formId?: string;
  constructor(taskPresenter: TaskPresenter) {
    super(taskPresenter);
  }

  get formId(): string | undefined {
    return this._formId;
  }

  override showFormAsync(session: FormFlowSession, activity: ShowFormActivity): Promise<void> {
    this._formId = activity.FormId;
    return super.showFormAsync(session, activity);
  }
}

async function getPageShellAsync(
  formProviderFn: FormProviderFn,
  formId: string,
  options: ShellOptions
): Promise<Shell> {
  const form = formProviderFn(formId);
  const uiContextOptions = options?.uiContextOptions || {};

  const headerTemplate = await loadTemplateAsync(
    uiContextOptions.headerTemplate || `${global.formFactorPath}/TaskHeaderTemplate2.ejs`
  );

  const contextOptions: ShellOptions = {
    ...options,
    ...{
      form,
      headerTemplate,
    },
    ...uiContextOptions.formOptions,
  };

  const result = await uiService.loadFormAsync(uiContextOptions.formType || PageType.Task2, contextOptions);

  if (global.formFactor === FormFactor.Tablet) {
    hookExpandArrowClickEvent(result[0]);
  }

  result[0] = result[0].find(CssClass.PageShell.Selector);
  return result;
}

function hookExpandArrowClickEvent($view: JQuery<unknown>): void {
  const $sideBar = $view.find(".g-sidebar");
  const $expandCaption = $view.find("#g-sidebar-expand-caption");
  const $restoreCaption = $view.find("#g-sidebar-restore-caption");
  $view.find(".g-sidebar-expand-arrow-wrap").on("click", () => {
    $sideBar.toggleClass("g-sidebar-expanded");
    $expandCaption.toggleClass("hide");
    $restoreCaption.toggleClass("hide");
  });
}

// TODO:
// - need to hook up form layout handler (when dialog resizes, control sizes e.g. grids should update)
// - hide expand bar (use standard alerts button for dialog instead)
// - add help button
// - add documents, edocs, etc. buttons to header
// - might want to have some of this code in UIService?
// - add some replaceDialogAsync function on the dialog service, so we don't have to hide + reshow between forms
// - need to fix anchoring
export async function getDialogShellAsync(
  state: DialogState,
  formProviderFn: FormProviderFn,
  dialogOptionOverrides: DialogOptions | undefined,
  formId: string,
  options: TaskShellOptions
): Promise<Shell> {
  options = { ...options };

  hideDialog(state);

  const bindingContext: BindingContext = new ko.bindingContext(options.viewModel);
  bindingContext.$contentViewModel = options.viewModel;
  if (options.viewModel) {
    options.viewModel.pageExtensions = options.pageExtensions;
  }

  const formPromise = formProviderFn(formId);
  const bodyDeferred = getBodyAsync(formPromise);
  const titleDeferred = getFormTitleAsync(formPromise, options.viewModel);

  /*! SuppressStringValidation CSS class name should not be translated */
  const dialogCss = "g-task-dialog2";
  const dialogOptions: DialogOptions = {
    autoresize: true,
    bodyAllowHtml: true,
    bodyDeferred,
    dialogCss,
    footer: " ",
    titleDeferred,
    titleAllowHtml: true,
    closeOnDismissOnly: true,
    viewModel: bindingContext,
  };

  if (dialogOptionOverrides?.autoresize !== undefined) {
    dialogOptions.autoresize = dialogOptionOverrides.autoresize;
  }

  if (dialogOptionOverrides?.maximized) {
    dialogOptions.maximized = dialogOptionOverrides.maximized;
  }

  if (dialogOptionOverrides?.showHeaderMenu) {
    addDialogHeaderMenuItems(options.viewModel, options.additionalMenuOptions || {});
    const headerTemplate = await loadTemplateAsync(global.formFactorPath + "/TaskHeaderTemplate2.ejs");
    dialogOptions.headerButtonsDeferred = Promise.resolve($(headerTemplate).filter("#headerMenuTemplate").html());
  }

  const dialog = await dialogService.showDialogAsync(dialogOptions);
  state.dialog = dialog;
  const $container = dialog.$dialog.find('div[data-role="gwShellContainer"].g-anchor-container:eq(0)');
  if ($container.length) {
    widgetFactory.hookResizableContainer($container);
  }

  return [dialog.$dialog.find(CssClass.PageShell.Selector), bindingContext];
}

async function getBodyAsync(formPromise: Promise<FormTemplate>): Promise<JQuery<HTMLElement>> {
  const form = await formPromise;
  const shellMarkup = await loadTemplateAsync(global.formFactorPath + "/" + PageType.DialogTask.Shell);
  const $body = $(shellMarkup);
  const $shell = $body.find(CssClass.PageShell.Selector);
  $shell.html(form.Markup);

  if (form.ExtenderFunc) {
    const materialDesignForm = form.MaterialDesign === true;
    $shell
      .find('>[data-role="gwShellContainer"]')
      .append(
        `<div data-bind="gwFormExtender: { extender: '${form.ExtenderFunc}', materialDesignForm: ${materialDesignForm} }">`
      );
  }

  return $body;
}

async function getFormTitleAsync(formPromise: Promise<FormTemplate>, viewModel: TaskViewModel): Promise<string> {
  const form = await formPromise;
  const formTitle = observeTitle(viewModel.getEntity(), form.Caption, form.CaptionArgs);

  // Title information can come from the BPMForm caption or from caption overrides in the viewModel (from the showForm activity).
  // We will feed the BPMForm caption to the function in viewModel to guarantee that overrides are used when available.
  const title = ko.asyncComputed(
    () => ({
      syncValue: getLoadingCaption(),
      asyncPromise: viewModel.getTitleAsync(formTitle()),
    }),
    () => formTitle() // Updates computed observable when formTitle updates, otherwise we'll get outdated information.
  );

  viewModel.title = title;

  // We can't just return a title string at this point as there is no way to get all the required data, so we need to bind to an observable.
  return '<span data-bind="text: title">';
}

function hideDialog(state: DialogState): void {
  if (state.dialog) {
    dialogService.hide(state.dialog);
    state.dialog = undefined;
  }
}

function addDialogHeaderMenuItems(viewModel: BasePageViewModel, options: MenuItemOptions): void {
  viewModel.favoritesViewModel = new FavoritesViewModel();
  viewModel.moreMenuItems = ko.pureComputed(() => {
    return [
      ...menuItemsProvider.getDocuments(options),
      ...menuItemsProvider.getNotesOptions(options),
      ...menuItemsProvider.getLogsOptions(options),
      ...menuItemsProvider.getWorkflowMenuItems(options),
      ...menuItemsProvider.getMessages(options),
    ];
  });
  viewModel.helpMenuItems = ko.pureComputed(() => {
    return menuItemsProvider.getHelpMenuItems({
      showHelpPage: helpPresenter.showHelpPage,
      showGPSDisclaimer: viewModel.isLocationTracking ? viewModel.showGPSDisclaimer : null,
      postNavigateByHref: viewModel.postNavigateByHref,
      openWiseLearning: () =>
        navigationService.post("#/support/openMyAccountUrl", {
          myAccountUrl: "https://myaccount.cargowise.com/Home/CargoWiseOneWiseLearning.aspx",
        }),
    });
  });
  viewModel.others = options.others;
  viewModel.jumpMenuItems = ko.pureComputed(menuItemsProvider.getJumpMenuItems);
  viewModel.workflowMenuItems = ko.pureComputed(menuItemsProvider.getWorkflowMenuItems.bind(null, options));
  viewModel.pinnableMenuItems = ko.pureComputed(() => {
    const pinnedFavorites = viewModel.favoritesViewModel!.pinnedFavorites();
    const moreMenuItems = viewModel.moreMenuItems!().filter((item: PinnableMenuItem) => item.isPinnable);
    const helpMenuItems = viewModel.helpMenuItems!().filter((item: PinnableMenuItem) => item.isPinnable);
    const jumpMenuItems: MenuItem[] = [
      viewModel.favoritesViewModel!.favoritesMenuItem,
      viewModel.favoritesViewModel!.recentsMenuItem,
    ];
    return pinnedFavorites.concat(moreMenuItems, helpMenuItems, jumpMenuItems);
  });
}

async function getDialogShellVueAsync(
  state: DialogState,
  formProviderFn: FormProviderFn,
  dialogOptionOverrides: DialogOptions | undefined,
  formId: string,
  options: TaskShellOptions
): Promise<Shell> {
  options = { ...options };
  hideDialogVue(state);

  const form = await formProviderFn(formId);
  if (!form.MaterialDesign) {
    return getDialogShellAsync(state, formProviderFn, dialogOptionOverrides, formId, options);
  }

  const shell = await loadTemplateAsync(PageType.DialogTaskVue.Shell);
  const bindingContext = new ko.bindingContext(options.viewModel);
  bindingContext.$contentViewModel = options.viewModel;
  if (options.viewModel) {
    options.viewModel.pageExtensions = options.pageExtensions;
  }

  const $template = $("<div>");
  $(shell).appendTo($template);
  $template.find("#content").html(form.Markup);
  $template.appendTo($(CssClass.ModalsContainer.Selector));

  if (form.ExtenderFunc) {
    $template
      .find("#content")
      .find('>[data-role="gwShellContainer"]')
      .append(
        `<div data-bind="gwFormExtender: { extender: '${form.ExtenderFunc}', materialDesignForm: true }" data-wtg-layout-grid-ignore>`
      );
  }

  let title: string | Computed<string> = form.FormID;

  if (form.CaptionArgs) {
    title = observeTitle(options.viewModel.getEntity(), form.Caption, form.CaptionArgs);
  } else if (form.Caption) {
    title = captionService.getStringFromInfo(form.Caption);
  }

  state.data = {
    showDialog: false,
    title,
  };

  const instance: Vue = await vueFactory.createVueInstanceAsync({
    contentContainer: $template[0],
    validationRegistrar: options.pageExtensions && options.pageExtensions.validationRegistrar,
    knockoutContext: bindingContext,
    bindingContext: options.viewModel,
    requiresParent: true,
    name: "GlowDialogContext",
    data: () => state.data,
    formId: form.PK,
  });

  if (!state.data) {
    state.data = { showDialog: true };
  }
  state.data.showDialog = true;
  state.element = instance.$el;
  return [$(state.element), bindingContext];
}

function hideDialogVue(state: DialogState): void {
  if (state.data && state.data.showDialog) {
    state.data.showDialog = false;
    const element = state.element;
    state.element = undefined;

    setTimeout(() => {
      if (element) {
        ko.removeNode(element);
      }
    }, 500);
  } else {
    hideDialog(state);
  }
}

export default new FormFlowUIContextProvider(defaultShellProviderFactory);
