import { onUnmounted, watch } from 'vue';
import { useUltimateDataItem } from 'VueHooks/UltimateDataItemHook';

export function useProposedValues(bindingPath) {
	const dataItemRef = useUltimateDataItem(bindingPath);
	return useProposedValuesWithDataItemRef(dataItemRef);
}

export function useProposedValuesWithDataItemRef(dataItemRef) {
	if (!dataItemRef) {
		return;
	}

	watch(
		() => dataItemRef.value,
		(dataItem, oldDataItem) => {
			stopProposedValuesListener(oldDataItem);
			startProposedValuesListener(dataItem);
		},
		{
			immediate: true,
		}
	);

	onUnmounted(() => stopProposedValuesListener(unwrapDataItem(dataItemRef)));
}

function startProposedValuesListener(dataItem) {
	if (dataItem && dataItem.entityAspect) {
		dataItem.entityAspect.proposedValues.startListeningAsync();
	}
}

function stopProposedValuesListener(dataItem) {
	if (dataItem && dataItem.entityAspect) {
		dataItem.entityAspect.proposedValues.stopListening();
	}
}

function unwrapDataItem(dataItem) {
	return dataItem && dataItem.value;
}
