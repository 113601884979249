import captionService from 'CaptionService';
import constants from 'Constants';
import MaterialDesignVueDialogService from './MaterialDesignVueDialogService';

class MaterialDesignTrackingDialogService extends MaterialDesignVueDialogService {
	constructor() {
		super();
	}

	showTrackingDialogAsync(viewModel, state) {
		const title = captionService.getString('9ed3536b-64aa-445c-877d-c300168c6b30', 'Track');
		return this.showDialogAsync(
			undefined,
			state,
			constants.MaterialDesignPageTypes.Tracking,
			viewModel,
			title
		);
	}
}

export default new MaterialDesignTrackingDialogService();
