import type { MetadataStore } from "breeze-client";

/** Get route name for the metadata store. */
export function getRouteName(metadataStore: MetadataStore): string {
  // TODO: implement correctly similar to EntityManagerExtensions instead of relying on BreezeInit setting this property
  if (!("_routeName" in metadataStore && typeof metadataStore._routeName === "string")) {
    throw new Error("Route name is not set for this metadata store");
  }

  return metadataStore._routeName;
}
