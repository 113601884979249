import Promise from 'bluebird';
import constants from 'Constants';

export const asyncStrategy = {
	map(array, mapper, callback) {
		return Promise.map(array, mapper).then(callback);
	},

	// eslint-disable-next-line rulesdir/async-function-suffix
	wait(awaitable, callback) {
		return Promise.wait(awaitable, callback);
	},

	waitAvailable(args, data, context, callback) {
		return Promise.map(args, (a) => (a ? a(data, context) : null)).then((results) => {
			for (let i = 0; i < results.length; i++) {
				const result = results[i];
				if (result) {
					if (result.state === constants.States.Available) {
						results[i] = result.value;
					} else {
						return result;
					}
				}
			}
			return callback(results);
		});
	},

	// eslint-disable-next-line rulesdir/async-function-suffix
	waitAvailableOne(arg, data, context, callback) {
		return Promise.wait(arg(data, context), (result) =>
			result.state === constants.States.Available ? callback(result.value) : result
		);
	},
};

export const syncStrategy = {
	map(array, mapper, callback) {
		return callback(array.map(mapper));
	},

	wait(awaitable, callback) {
		return callback(awaitable);
	},

	waitAvailable(args, data, context, callback) {
		const values = new Array(args.length);
		for (let i = 0; i < args.length; i++) {
			const arg = args[i];
			if (arg) {
				const result = arg(data, context);
				if (result.state === constants.States.Available) {
					values[i] = result.value;
				} else {
					return result;
				}
			} else {
				values[i] = null;
			}
		}

		return callback(values);
	},

	waitAvailableOne(arg, data, context, callback) {
		const result = arg(data, context);
		return result.state === constants.States.Available ? callback(result.value) : result;
	},
};
