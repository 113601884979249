/*global __webpack_public_path__:true*/ // eslint-disable-line no-unused-vars
import 'ModuleMap';
import '../Content/Themes/Desktop/main.less';
import bootstrapperAsync from 'Bootstrapper';

if (!window.glow.scriptLoadError) {
	__webpack_public_path__ = window.glow.rootPath + 'dist/dsk/';

	window.glow.moduleMap.setPath(__webpack_public_path__);

	if (window.glow.useCompiledRulesets) {
		/*! SuppressStringValidation Relative path. */
		window.glow.rulesetLoader.setPath(window.glow.rootPath + 'dist/rulesets/');
	}

	bootstrapperAsync('DSK', 'Desktop');
}
