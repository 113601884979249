import Promise from 'bluebird';
import languageService from 'LanguageService';
import oData from 'OData';
import {
	addODataETagHeader,
	getODataHeaders,
	getODataRoute,
	MetadataType,
} from 'ODataUtils';
import _ from 'underscore';

function DataService() {}

DataService.prototype.invokeRequestAsync = (request) => {
	request.requestUri = getODataRoute(request.requestUri);
	request.headers = getODataHeaders(MetadataType.Verbose, request.headers);

	const languageCode = languageService.getLanguageCode();
	if (languageCode) {
		/*! SuppressStringValidation Header name*/
		request.headers['Enterprise-Language-Code'] = languageCode;
	}

	return new Promise((resolve, reject) => {
		oData.request(
			request,
			resolve,
			reject
		);
	});
};

DataService.prototype.getAsync = function (requestUri) {
	/*! StartNoStringValidationRegion Headers */
	const request = {
		headers: { 'Cache-Control': 'no-cache' }, // Not sure if this actually does anything.
		requestUri,
		method: 'GET',
		data: null,
	};
	/*! EndNoStringValidationRegion */

	return this.invokeRequestAsync(request);
};

DataService.prototype.saveAsync = function (data) {
	const metadata = data.__metadata;
	data = _.extend({}, data);

	const headers = { 'Content-Type': 'application/json' };
	addODataETagHeader(headers, data);

	delete data.__metadata;

	let method;
	if (metadata.isNew) {
		method = 'POST';
	} else {
		method = 'PATCH';
	}

	const request = {
		headers,
		requestUri: metadata.uri,
		method,
		data,
	};

	return this.invokeRequestAsync(request);
};

DataService.prototype.deleteAsync = function (entity) {
	const headers = {};

	addODataETagHeader(headers, entity);

	const request = {
		requestUri: entity.__metadata.uri,
		method: 'DELETE',
		headers,
	};

	return this.invokeRequestAsync(request);
};

DataService.prototype.invokeActionAsync = function (uri, requestData) {
	const request = {
		requestUri: uri,
		method: 'POST',
		data: requestData,
	};

	return this.invokeRequestAsync(request);
};

export default new DataService();
