/*! StartNoStringValidationRegion Route Names */
interface RouteNames {
  primary: string[];
  isolated: string[];
}

const routeNames: RouteNames = {
  primary: [
    "Accounting",
    "Accreditation",
    "AssetManagement",
    "BPM",
    "BPMSummary",
    "Brokerage",
    "BufferManagement",
    "Configurations",
    "ContainerYard",
    "CustomsUSAMS",
    "CustomsUSISF",
    "CustomsUSReference",
    "CustomerService",
    "DataTransfer",
    "Documents",
    "DomesticTransport",
    "ErrorReporter",
    "Filters",
    "Forwarding",
    "GateManagement",
    "Global",
    "HumanResourcesManagement",
    "ImageEngine",
    "Incident",
    "Index",
    "ItemTracking",
    "LandTransportNonPersistent",
    "LocationTracking",
    "Logon",
    "Mail",
    "Markups",
    "Media",
    "Netting",
    "OceanCarrier",
    "Organisation",
    "PersonalEffects",
    "ProductionRulesEngine",
    "Recruitment",
    "Rating",
    "Reference",
    "RemoteDeviceManagement",
    "ResourceString",
    "SecurityManagement",
    "Settings",
    "Support",
    "System",
    "TransitWarehouse",
    "Warehouse",
    "Telematics",
  ],
  isolated: ["PublicResources"],
};

export default routeNames;
/*! EndNoStringValidationRegion */
