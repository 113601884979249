import appConfig from 'AppConfig';
import dialogService from 'DialogService';
import errors from 'Errors';
import global from 'Global';
import { trackBusyStateAsync } from 'GlobalBusyStateTracker';
import log from 'Log';
import { loadControllerAsync } from 'ModuleLoader';
import { resolveLastNavigation } from 'NavigationTracker';
import RouteRegistry from 'RouteRegistry';
import defineRoutes from 'Routes';
import Promise from 'bluebird';
import $ from 'jquery';
import sammy from 'sammy';

const maxHistoryStack = 10;

sammy.log = function () {
	log.info.apply(log, arguments);
};

function NavigationService() {
	this.window = window;
	this.reset();
}

NavigationService.prototype.reset = function () {
	this.navigatingHandlers = [];
	this.history = [];
	this._executingContext = null;
};

NavigationService.prototype.setPageContext = function (pageContext) {
	this._pageContext = pageContext;
};

function addUrlToHistory(history, url) {
	url = getHashPart(url);
	if (history.length === 0 || history[0] !== url) {
		history.unshift(url);
	}

	if (history.length > maxHistoryStack) {
		history.splice(maxHistoryStack, history.length - maxHistoryStack);
	}
}

function route(navigationService, routeInfo) {
	/** @this Sammy.Application.context_prototype */
	const callback = function () {
		const currentPageContext = navigationService._pageContext;
		const isValid = validateParams(this.params, routeInfo.validation);
		if (!isValid) {
			return this.notFound(this.verb, this.path);
		}

		const actionParams = $.makeArray(arguments);
		const shouldLockRoute = this.verb === 'get' && routeInfo.shouldTriggerEvent !== false;

		if (shouldLockRoute) {
			navigationService._executingContext = actionParams[0];
		}

		const promise = (async () => {
			try {
				try {
					const controller = await loadControllerAsync(routeInfo.controller);
					if (actionParams[0] !== this) {
						actionParams.unshift(this);
					}
					await controller[routeInfo.action].apply(controller, actionParams);

					if (routeInfo.shouldTriggerEvent !== false) {
						this.$element().trigger('routeNavigated', this.path);
					}
				} finally {
					if (shouldLockRoute) {
						navigationService._executingContext = null;
						resolveLastNavigation();
					}
				}
			} catch (error) {
				if (
					shouldLockRoute &&
					!errors.isCritical(error) &&
					navigationService._pageContext === currentPageContext
				) {
					navigationService.goBack('#/' + appConfig.initialPage, null, true);
				}
				throw error;
			}
		})();
		return trackBusyStateAsync(promise);
	};

	callback.isPublic = routeInfo.isPublic;
	callback.intercept = routeInfo.intercept;
	callback.skipAgreement = routeInfo.skipAgreement;
	return callback;
}

function setupRequestInterceptor(app, navigationService) {
	/** @this Sammy.Application.context_prototype */
	async function interceptorHandlerAsync(callback) {
		const route = this.app.lookupRoute(this.verb, this.path);
		if (this.verb === 'post' && !route.callback.intercept) {
			return callback();
		} else if (navigationService._executingContext) {
			return navigationService.changeUriAsync(navigationService.history[0]);
		} else {
			const canNavigate = await navigationService.canNavigateAsync();
			if (!canNavigate) {
				const previousUri = navigationService.history[0];
				if (!previousUri) {
					return;
				}
				return navigationService.changeUriAsync(previousUri);
			}
			if (navigationService.navigatingHandlers.length) {
				for (const handler of navigationService.navigatingHandlers) {
					await handler.onNavigate();
				}

				navigationService.navigatingHandlers = [];
				addUrlToHistory(navigationService.history, this.app.getLocation());
				return callback();
			} else {
				addUrlToHistory(navigationService.history, this.app.getLocation());
				return callback();
			}
		}
	}

	app.around(interceptorHandlerAsync);
}

function setup(navigationService, options) {
	return sammy(
		appConfig.contentContainer,
		(app) => {
			app.disable_push_state = true;

			const registry = new RouteRegistry(app, route.bind(null, navigationService));
			options.defineRoutes(registry);

			app.error = (msg, originalError) => {
				throw originalError;
			};

			// 404
			app.notFound = function (verb, path) {
				/*! SuppressStringValidation String validation suppressed in initial refactor */
				log.error(['Unable to find route for', verb, path].join(' '));
				if (options.onNotFound) {
					const context = new sammy.EventContext(app, verb, path);
					options.onNotFound(context);
				}
			};

			app.after(() => {
				navigationService.previousGlowPageUrl = $.url().data.attr.fragment;
			});

			setupRequestInterceptor(app, navigationService);
		}
	);
}

function setLocation(self, url) {
	try {
		self.app.setLocation(url);
	} catch (error) {
		throw new errors.SetLocationError(self.app.last_location, url, error);
	}
}

NavigationService.prototype.onNavigating = function (canNavigate, onNavigate) {
	// use a reference to the array, not this, so that dispose after changing the navigatingHandlers array has no effect
	const navigatingHandlers = this.navigatingHandlers;
	const handler = {
		canNavigate,
		onNavigate: onNavigate || Promise.resolve,
	};
	navigatingHandlers.push(handler);
	return {
		dispose() {
			const index = navigatingHandlers.indexOf(handler);
			navigatingHandlers.splice(index, 1);
		},
	};
};

NavigationService.prototype.init = function (options) {
	options = $.extend(
		{
			defineRoutes,
		},
		options
	);

	this.app = setup(this, options);
};

NavigationService.prototype.start = function () {
	this.app.run('#/');
};

NavigationService.prototype.goBack = function (defaultPath, context, changeUriOnly) {
	const previousUri = this.history.length > 1 ? this.history[1] : defaultPath;
	if (changeUriOnly) {
		this.changeUriAsync(previousUri);
	} else {
		this.changePage(previousUri, context);
	}
};

NavigationService.prototype.reloadPage = function (forcedReload) {
	this.window.location.reload(forcedReload || false);
};

NavigationService.prototype.changePage = function (url, context) {
	if (this._executingContext && context === this._executingContext) {
		this._executingContext = null;
	}

	url = getUriAsFragment(url);
	if (this.isNavigatingToCurrentRoute(url)) {
		this.app.refresh();
	} else {
		setLocation(this, url);
	}
};

NavigationService.prototype.changePageWithReload = function (url) {
	url = getUriAsFragment(url);
	this.changeUriAsync(url);
	this.window.location.reload();
};

NavigationService.prototype.isNavigatingToCurrentRoute = function (url) {
	const lastUrl = this.app.getLocation().toLowerCase();
	const newCompleteUrl = (global.rootPathForModule + global.formFactorPath + url).toLowerCase();
	const newUrl = url.toLowerCase();
	return newUrl === lastUrl || newCompleteUrl === lastUrl;
};

NavigationService.prototype.isNavigatingToPublicRoute = function (context) {
	const route = this.app.lookupRoute(context.verb, context.path);
	return !!route.callback.isPublic;
};

NavigationService.prototype.routeRequiresAgreement = function (context) {
	const route = this.app.lookupRoute(context.verb, context.path);
	return !route.callback.skipAgreement;
};

NavigationService.prototype.changeUriAsync = function (uri, hideFromHistory) {
	const self = this;
	const window = self.window;

	const currentLocationHash = window.location.hash;
	if (currentLocationHash === getHashPart(uri)) {
		return Promise.resolve();
	}

	self.app._location_proxy.unbind();

	const rebindLocationProxy = () => {
		self.app._location_proxy.bind();
	};

	return new Promise((resolve) => {
		$(window).on('hashchange.rebindProxy', () => {
			$(window).off('hashchange.rebindProxy');
			rebindLocationProxy();
			resolve();
		});
		setLocation(self, uri);
		self.app.last_location = uri;
		if (!hideFromHistory) {
			addUrlToHistory(self.history, uri);
		}
	// eslint-disable-next-line rulesdir/prefer-async-await
	}).finally(() => {
		resolveLastNavigation();
	});
};

NavigationService.prototype.fullPath = () => {
	return $(location).attr('href');
};

// Note that params don't get included in history, and that navigating back/forward to this URI in the browser will mean navigating without params
NavigationService.prototype.get = async function (path, params) {
	await this.changeUriAsync(path, true);
	/*! SuppressStringValidation HTTP verbs are not translatable */
	return this.app.runRoute('get', path, params);
};

NavigationService.prototype.post = function (path, params) {
	/*! SuppressStringValidation HTTP verbs are not translatable */
	return this.app.runRoute('post', path, params);
};

NavigationService.prototype.getCurrentLocation = function () {
	return this.app.getLocation();
};

NavigationService.prototype.canNavigateAsync = async function () {
	if (dialogService.isDialogOpen(dialogService.dialogTypes.ReportError)) {
		return false;
	}
	if (!this.navigatingHandlers.length) {
		return true;
	}

	const canNavigateResults = await Promise.all(
		this.navigatingHandlers.map((handler) => handler.canNavigate())
	);

	return canNavigateResults.every(Boolean);
};

function getUriAsFragment(uri) {
	if (uri.indexOf('#') < 0) {
		if (uri.indexOf('/') !== 0) {
			uri = '/' + uri;
		}

		uri = '#' + uri;
	}

	return uri;
}

function getHashPart(uri) {
	return uri.substring(uri.indexOf('#'));
}

function validateParams(params, validation) {
	if (validation) {
		for (const name in validation) {
			if (!(name in params)) {
				throw new Error('Parameter "' + name + '" does not exist in the route.');
			}

			const isValidParam = validation[name](params[name]);
			if (!isValidParam) {
				return false;
			}
		}
	}

	return true;
}

export default new NavigationService();
