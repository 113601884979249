import ajaxService from 'AjaxService';
import entityMappingService from 'EntityMappingService';
import errors from 'Errors';
import global from 'Global';
import { loadRulesetAsync } from 'ModuleLoader';
import Rule from 'Rule';
import { extractDerivedRulesets } from 'RuleExtractor';
import Promise from 'bluebird';
import _ from 'underscore';

function RuleRepository() {
	this.clear();
	this._registrars = {};
}

RuleRepository.prototype.clear = function () {
	this._entities = {};
	this._loadedRoutes = {};
};

RuleRepository.prototype.addRegistrar = function (name, callback) {
	this._registrars[name] = callback;
};

RuleRepository.prototype.get = function (entityName, okIfNotFound) {
	const result = this._entities[entityName];
	if (!result && !okIfNotFound) {
		throw new Error('Rules have not been loaded for "' + entityName + '" yet.');
	}
	return result || null;
};

RuleRepository.prototype.loadEntityAsync = function (entityName) {
	const result = this._entities[entityName];
	if (result) {
		return Promise.resolve(result);
	}
	else {
		const self = this;
		const routeName = entityMappingService.getFirstRouteNameObsoleteDoNotUse(entityName);
		return this.loadRouteAsync(routeName).then(() => {
			return self._entities[entityName];
		});
	}
};

RuleRepository.prototype.loadRouteAsync = function (routeName) {
	const self = this;
	if (this._loadedRoutes[routeName]) {
		return Promise.resolve();
	}
	else {
		return loadRouteCoreAsync(routeName).then((ruleSets) => {
			const entitiesOut = {};
			extractDerivedRulesets(ruleSets);
			for (let i = 0; i < ruleSets.length; i++) {
				const ruleSet = ruleSets[i];
				entitiesOut[ruleSet.entityName] = getRulesForRuleSet(self, ruleSet);
			}

			_.extend(self._entities, entitiesOut);
			self._loadedRoutes[routeName] = true;
		});
	}
};

function loadRouteCoreAsync(routeName) {
	if (global.useCompiledRulesets) {
		return loadRulesetAsync(routeName);
	} else {
		/*! SuppressStringValidation (No caption here) */
		const uri = global.serviceUri + 'api/rulesets/route/' + routeName;
		return ajaxService.ajaxAsync(uri, { dataType: 'json' }).catch((error) => {
			if (error instanceof ajaxService.AjaxError) {
				throw new errors.LoadRulesetException(routeName, error);
			}
			throw error;
		});
	}
}

function getRulesForRuleSet(self, ruleSet) {
	const container = {
		addressEditMode: ruleSet.addressEditMode,
		allColumns: ruleSet.allColumns,
		allFilterKeys: ruleSet.allFilterKeys,
		attachable: ruleSet.attachable,
		availableColumns: ruleSet.availableColumns,
		barcodeParsing: ruleSet.barcodeParsing,
		characterCasing: ruleSet.characterCasing,
		charBoolean: ruleSet.charBoolean,
		codeProperty: ruleSet.codeProperty,
		colorSchemeProperty: ruleSet.colourSchemeProperty,
		conditions: ruleSet.conditions,
		docManagerCode: ruleSet.docManagerCode,
		hideEDocs: ruleSet.hideEDocs,
		defaultDerivedTypeName: ruleSet.defaultDerivedTypeName,
		defaultDisplayMode: ruleSet.defaultDisplayMode,
		defaultMaintainFormFlow: ruleSet.defaultMaintainFormFlow,
		defaultRemoveFormFlow: ruleSet.defaultRemoveFormFlow,
		defaultActivateFormFlow: ruleSet.defaultActivateFormFlow,
		defaultRemoveFormFlowForProperties: ruleSet.defaultRemoveFormFlowForProperties,
		defaultAdvancedSearchMode: ruleSet.defaultAdvancedSearchMode,
		descriptionProperty: ruleSet.descriptionProperty,
		dateTimeType: ruleSet.dateTimeType,
		dbMappingOverride: ruleSet.dbMappingOverride,
		documentContext: ruleSet.documentContext,
		documentSources: ruleSet.documentSources,
		entityFieldConfigurations: ruleSet.entityFieldConfigurations,
		eventSources: ruleSet.eventSources,
		expandPaths: ruleSet.expandPaths || {},
		propertiesFieldConfigurations: ruleSet.propertiesFieldConfigurations,
		filterKeys: ruleSet.filterKeys,
		formFlowActions: ruleSet.formFlowActions,
		hierarchy: ruleSet.hierarchy,
		icon: ruleSet.icon,
		isActiveProperty: ruleSet.isActiveProperty,
		isConversationProvider: ruleSet.isConversationProvider,
		isImportable: ruleSet.isImportable,
		isReadOnly: ruleSet.isReadOnly,
		maxLength: ruleSet.maxLength,
		nonExpandable: ruleSet.nonExpandable,
		numericSize: ruleSet.numericSize,
		numericRange: ruleSet.numericRange,
		propertyReadOnly: ruleSet.propertyReadOnly,
		quickSearchPaths: ruleSet.quickSearchPaths,
		removable: ruleSet.removable,
		removalMode: ruleSet.removalMode,
		typeDescriptionProperty: ruleSet.typeDescriptionProperty,
		unitFilter: ruleSet.unitFilter,
        unitStrategy: ruleSet.unitStrategy,
		userActivityNotification: ruleSet.userActivityNotification,
		availableNoteTypes: ruleSet.availableNoteTypes,
		allowEntityActionsWhenInactive: ruleSet.allowEntityActionsWhenInactive,
		workflow: ruleSet.workflow,
		hideWorkflow: ruleSet.hideWorkflow
	};

	addRules(container, ruleSet.rules);
	invokeRegistrars(self, container, ruleSet);

	return container;
}

function addRule(rulesForType, propertyName, rule) {
	let propertyRules = rulesForType[propertyName];

	if (!propertyRules) {
		rulesForType[propertyName] = propertyRules = [];
	}

	propertyRules.push(rule);
}

function addRules(container, ruleDefinitions) {
	if (!ruleDefinitions) {
		return;
	}
	for (const ruleType in ruleDefinitions) {
		const localRuleType = ruleType.charAt(0).toLowerCase() + ruleType.slice(1);
		let rulesForType = container[localRuleType];
		if (!rulesForType) {
			container[localRuleType] = rulesForType = {};
		}

		for (let i = 0, length = ruleDefinitions[ruleType].length; i < length; i++) {
			const item = ruleDefinitions[ruleType][i];
			item.ruleType = localRuleType;
			item.expandPaths = container.expandPaths[item.property];
			const rule = new Rule(item);
			addRule(rulesForType, item.property, rule);
		}
	}
}

function invokeRegistrars(self, container, ruleSet) {
	for (const name in self._registrars) {
		const item = self._registrars[name](ruleSet);
		if (item) {
			container[name] = item;
		}
	}
}

export default new RuleRepository();
