import Promise from 'bluebird';

export default class ValidationRegistrar {
	constructor() {
		this._innerSet = new Set();
	}

	getBoundItemsAsync() {
		return Promise.map(this._innerSet, (itemGetter) => {
			return itemGetter();
		}).then((results) => {
			return results
				.flat()
				.filter((e) => e);
		});
	}

	registerBoundItem(itemGetter) {
		this._innerSet.add(itemGetter);
	}

	unregisterBoundItem(itemGetter) {
		this._innerSet.delete(itemGetter);
	}
}
