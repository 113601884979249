import { provide, inject } from 'vue';

export const validationRegistrarInjectionKey = Symbol('validationRegistrar');

export function useValidationRegistrar() {
	return inject(validationRegistrarInjectionKey, undefined);
}

export function setValidationRegistrar(validationRegistrar) {
	provide(validationRegistrarInjectionKey, validationRegistrar);
}
