import bindingEvaluator from 'BindingEvaluator';
import notificationType from 'NotificationType';
import Notifications from 'Notifications';
import { useBindingContext } from 'VueHooks/BindingContextHook';
import { useKnockoutContext } from 'VueHooks/KnockoutContextHook';
import { getUltimateDataItemRef } from 'VueHooks/UltimateDataItemHook';
import { useValidationRegistrar } from 'VueHooks/ValidationRegistrarHook';
import ko from 'knockout';
import { reactive, isRef, onUnmounted, watch } from 'vue';

export function useValidationState(bindingPath) {
	const bindingContext = useBindingContext();
	return useValidationStateWithBindingContext(bindingContext, bindingPath);
}

export function useValidationStateWithBindingContext(bindingContext, bindingPath) {
	const koContext = useKnockoutContext();
	const validationRegistrar = useValidationRegistrar();

	const [validationState, dispose] = getValidationState(
		bindingPath,
		bindingContext,
		koContext,
		validationRegistrar
	);

	onUnmounted(() => dispose());

	return validationState;
}

export function getValidationState(bindingPath, bindingContext, koContext, validationRegistrar) {
	const targetProperty = bindingEvaluator.getPropertyName(bindingPath);
	const validationState = reactive({
		alertLevel: notificationType.Success,
		targetKey: undefined,
		targetProperty,
		messages: [],
		error: false,
		warning: false,
	});

	if (!bindingPath) {
		return [validationState, () => { }];
	}

	const [dataItem, disposeDataItem] = getUltimateDataItemRef(bindingPath, bindingContext, koContext);
	if (!dataItem) {
		return [validationState, () => { }];
	}

	let computed;
	const unwatch = watch(
		() => isRef(dataItem) ? dataItem.value : dataItem,
		(dataItem) => {
			computed && computed.dispose();
			if (dataItem) {
				computed = ko.computed(() => {
					const notifications = Notifications.get(dataItem);
					if (notifications) {
						const alerts = notifications.alerts(targetProperty);
						if (alerts.length > 0) {
							validationState.alertLevel = notifications.level(targetProperty);
							validationState.targetKey = alerts[0].entityPK;
							validationState.messages = [alerts[0].Text];
							validationState.error =
								validationState.alertLevel === notificationType.Error ||
								validationState.alertLevel === notificationType.MessageError;
							validationState.warning = validationState.alertLevel === notificationType.Warning;
						} else {
							setEmptyValidationState(validationState);
						}
					} else {
						setEmptyValidationState(validationState);
					}
				});
			} else {
				computed = undefined;
			}
		},
		{ immediate: true }
	);

	const unregisterBoundItem = registerBoundItem(bindingPath, bindingContext, koContext, validationRegistrar);

	return [
		validationState,
		() => {
			computed && computed.dispose();
			disposeDataItem();
			unregisterBoundItem();
			unwatch();
		}
	];
}

function setEmptyValidationState(validationState) {
	validationState.alertLevel = notificationType.Success;
	validationState.targetKey = undefined;
	validationState.messages = [];
	validationState.error = false;
	validationState.warning = false;
}

function registerBoundItem(bindingPath, bindingContext, koContext, validationRegistrar) {
	if (validationRegistrar) {
		const propertyGetterFunc = getBoundPropertyAsync.bind(
			null,
			bindingContext,
			koContext,
			bindingPath
		);

		validationRegistrar.registerBoundItem(propertyGetterFunc);
		return () => validationRegistrar.unregisterBoundItem(propertyGetterFunc);
	}

	return () => { };
}

function getBoundPropertyAsync(bindingContextRef, koContext, bindingPath) {
	return bindingEvaluator
		.loadUltimateDataItemAsync(koContext, bindingContextRef.value, bindingPath)
		.then((entity) => {
			if (entity && entity.entityAspect) {
				const propertyName = bindingEvaluator.getPropertyName(bindingPath);
				return {
					entity,
					propertyNames: [propertyName],
				};
			}
		});
}
