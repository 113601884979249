const { DeferredPromise } = require('DeferredPromise');
const Promise = require('bluebird.base'); // Should remove this line after bluebird is remove from bluebirdPolyfills

const Q = {};

Q.resolve = Promise.resolve;
Q.reject = Promise.reject;
Q.defer = function () {
    return new DeferredPromise();
};

module.exports = Q;