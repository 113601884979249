import constants from 'Constants';
import ruleRepository from 'RuleRepository';
import * as stringUtils from 'StringUtils';
import ko from 'knockout';
import _ from 'underscore';

function RuleService(entityName, rules) {
	this.entityName = entityName;
	this._rules = rules;
}

RuleService.get = (entityName, okIfNotFound) => {
	const rules = ruleRepository.get(entityName, okIfNotFound);
	return rules && new RuleService(entityName, rules);
};

RuleService.loadAsync = (entityName) => {
	return ruleRepository.loadEntityAsync(entityName).then((rules) => {
		return new RuleService(entityName, rules);
	});
};

RuleService.observe = (entityName) => {
	const rule = RuleService.get(entityName, true);
	if (!rule) {
		ko.promiseObserver(RuleService.loadAsync(entityName)).read();
	}
	return rule;
};

RuleService.prototype.allColumns = function () {
	return this._rules.allColumns || [];
};

RuleService.prototype.allFilterKeys = function () {
	return this._rules.allFilterKeys || [];
};

RuleService.prototype.entityFieldConfigurations = function () {
	return this._rules.entityFieldConfigurations || [];
};

RuleService.prototype.availableColumns = function () {
	const result = this._rules.availableColumns;
	if (result) {
		return result.map((column) => {
			return _.extend({}, column);
		});
	}
	return [];
};

RuleService.prototype.propertyFieldConfiguration = function (propertyName) {
	const fieldConfigurations = this._rules.propertiesFieldConfigurations;
	return (fieldConfigurations && fieldConfigurations[propertyName]) || null;
};

RuleService.prototype.isActiveProperty = function () {
	return this._rules.isActiveProperty;
};

RuleService.prototype.isConversationProvider = function () {
	return !!this._rules.isConversationProvider;
};

RuleService.prototype.isImportable = function () {
	return this._rules.isImportable || false;
};

RuleService.prototype.docManagerCode = function () {
	return this._rules.docManagerCode;
};

RuleService.prototype.hideEDocs = function () {
	return this._rules.hideEDocs === true;
};

RuleService.prototype.canViewDocuments = function () {
	return !!this._rules.documentSources || !!this._rules.documentContext;
};

RuleService.prototype.defaultMaintainFormFlow = function () {
	return this._rules.defaultMaintainFormFlow;
};

RuleService.prototype.defaultDerivedTypeName = function () {
	return this._rules.defaultDerivedTypeName;
};

RuleService.prototype.codeProperty = function () {
	return this._rules.codeProperty || null;
};

RuleService.prototype.colorSchemeProperty = function () {
	return this._rules.colorSchemeProperty || null;
};

RuleService.prototype.commandRule = function (propertyName, okIfNotFound) {
	/*! SuppressStringValidation String validation suppressed in initial refactor */
	return getRule(this, 'command', propertyName, okIfNotFound);
};

RuleService.prototype.condition = function (id, okIfNotFound) {
	const conditions = this._rules.conditions;
	const result = conditions ? (this._rules.conditions[id] || null) : null;

	if (!result && !okIfNotFound) {
		/*! SuppressStringValidation Exception message */
		throw new Error(stringUtils.format('Could not find condition with id "{0}" on {1}.', id, this.entityName));
	}

	return result;
};

RuleService.prototype.dbMappingOverride = function (propertyName) {
	const items = this._rules.dbMappingOverride;
	return items ? (items[propertyName] || null) : null;
};

RuleService.prototype.defaultDisplayMode = function () {
	return this._rules.defaultDisplayMode || constants.DropDownDisplayModes.Unspecified;
};

RuleService.prototype.descriptionProperty = function () {
	return this._rules.descriptionProperty || null;
};

RuleService.prototype.isReadOnly = function () {
	return this._rules.isReadOnly || false;
};

RuleService.prototype.expandPaths = function (propertyName) {
	const items = this._rules.expandPaths;
	return items ? (items[propertyName] || null) : null;
};

RuleService.prototype.defaultAdvancedSearchMode = function () {
	return this._rules.defaultAdvancedSearchMode;
};

RuleService.prototype.formFlowActions = function (propertyName, formFlowPK) {
	const actions = this._rules.formFlowActions;
	if (actions) {
		const actionsForProperty = actions[propertyName];
		if (actionsForProperty) {
			const formFlowActionDefinitions = actionsForProperty.formFlowActionDefinitions;
			if (formFlowActionDefinitions) {
				return formFlowActionDefinitions.find((a) => a.formFlowPK === formFlowPK);
			}
		}
	}
};

RuleService.prototype.unitFilter = function (propertyName) {
	const items = this._rules.unitFilter;
	return items ? (items[propertyName] || null) : null;
};

RuleService.prototype.lookupMetadata = function (propertyName) {
	const items = this._rules.lookupMetadata;
	return items ? (items[propertyName] || null) : null;
};

RuleService.prototype.lookupRule = function (propertyName, okIfNotFound) {
	/*! SuppressStringValidation String validation suppressed in initial refactor */
	return getRule(this, 'lookup', propertyName, okIfNotFound);
};

RuleService.prototype.lookupRuleById = function (id, okIfNotFound) {
	const rules = this._rules.lookup;
	if (rules) {
		for (const propertyName in rules) {
			if (Object.prototype.hasOwnProperty.call(rules, propertyName)) {
				const propertyRules = rules[propertyName];
				const result = _.findWhere(propertyRules, { ruleId: id });
				if (result) {
					return result;
				}
			}
		}
	}

	if (okIfNotFound) {
		return null;
	}

	/*! SuppressStringValidation Exception message */
	throw new Error(stringUtils.format('Could not find rule with id "{0}" on {1}.', id, this.entityName));
};

RuleService.prototype.maxLength = function (propertyName) {
	const maxLength = this._rules.maxLength;
	return maxLength ? (maxLength[propertyName] || null) : null;
};

RuleService.prototype.propertyReadOnly = function (propertyName) {
	const propertyReadOnly = this._rules.propertyReadOnly;
	const propertyReadOnlyValue = propertyReadOnly ? propertyReadOnly[propertyName] : null;
	return propertyReadOnlyValue !== undefined ? propertyReadOnlyValue : null;
};

RuleService.prototype.nonExpandable = function () {
	return !!this._rules.nonExpandable;
};

RuleService.prototype.numericSize = function (propertyName) {
	return this._rules.numericSize ? (this._rules.numericSize[propertyName] || null) : null;
};

RuleService.prototype.numericRange = function (propertyName) {
	return this._rules.numericRange ? (this._rules.numericRange[propertyName] || null) : null;
};

RuleService.prototype.hierarchy = function (propertyName) {
	const hierarchy = this._rules.hierarchy;
	return hierarchy ? (hierarchy[propertyName] || hierarchy) : null;
};

RuleService.prototype.unitStrategy = function (propertyName) {
	const unitStrategy = this._rules.unitStrategy;
	return unitStrategy ? (unitStrategy[propertyName] || null) : null;
};

RuleService.prototype.eventSources = function () {
	return this._rules.eventSources || null;
};

RuleService.prototype.conditions = function () {
	return this._rules.conditions || null;
};

RuleService.prototype.documentSources = function () {
	return this._rules.documentSources || null;
};

RuleService.prototype.documentContext = function () {
	return this._rules.documentContext || null;
};

RuleService.prototype.icon = function () {
	return this._rules.icon || null;
};

RuleService.prototype.dateTimeType = function (propertyName) {
	return this._rules.dateTimeType ? (this._rules.dateTimeType[propertyName] || null) : null;
};

RuleService.prototype.propertyRule = function (propertyName, okIfNotFound) {
	/*! SuppressStringValidation String validation suppressed in initial refactor */
	return getRule(this, 'property', propertyName, okIfNotFound);
};

RuleService.prototype.propertyRules = function () {
	let result = [];
	const rules = this._rules.property;

	if (rules) {
		for (const name in rules) {
			result = result.concat(rules[name]);
		}
	}

	return result;
};

RuleService.prototype.dependentProperties = function (propertyName) {
	const result = new Set();
	result.add(propertyName);

	const dependentPropertiesMap = this.propertyRules().reduce((map, rule) => {
		rule.getAllDependencies().forEach((dependency) => {
			dependency.getAllDependencyPaths().forEach((dependencyPath) => {
				const separatorIndex = dependencyPath.indexOf('.');
				const dependentProperty =
					separatorIndex > -1 ? dependencyPath.substr(0, separatorIndex) : dependencyPath;
				let properties = map.get(dependentProperty);
				if (!properties) {
					properties = new Set();
					map.set(dependentProperty, properties);
				}
				properties.add(rule.property);
			});
		});
		return map;
	}, new Map());

	findDependentProperties(dependentPropertiesMap, propertyName, result);
	return Array.from(result);
};

function findDependentProperties(dependentPropertiesMap, propertyName, result) {
	const entry = dependentPropertiesMap.get(propertyName);
	if (entry) {
		entry.forEach((nextProperty) => {
			if (!result.has(nextProperty)) {
				result.add(nextProperty);
				findDependentProperties(dependentPropertiesMap, nextProperty, result);
			}
		});
	}
}

RuleService.prototype.proposedValueRules = function () {
	return this._rules.proposedValue || {};
};

RuleService.prototype.removalMode = function (propertyName) {
	const removalMode = this._rules.removalMode;
	return (removalMode && removalMode[propertyName]) || null;
};

RuleService.prototype.removable = function () {
	return this._rules.removable || null;
};

RuleService.prototype.validationRules = function () {
	return this._rules.validation || {};
};

RuleService.prototype.filterKeys = function () {
	return this._rules.filterKeys || [];
};

RuleService.prototype.typeDescriptionProperty = function () {
	return this._rules.typeDescriptionProperty || null;
};

RuleService.prototype.quickSearchPaths = function () {
	return this._rules.quickSearchPaths || [];
};

RuleService.prototype.addressEditMode = function (propertyName) {
	const addressEditMode = this._rules.addressEditMode;
	return (addressEditMode && addressEditMode[propertyName]) || null;
};

RuleService.prototype.barcodeParsing = function (propertyName) {
	const barcodeParsing = this._rules.barcodeParsing;
	return (barcodeParsing && barcodeParsing[propertyName]) || null;
};

RuleService.prototype.characterCasing = function (propertyName) {
	const characterCasing = this._rules.characterCasing;
	return (characterCasing && characterCasing[propertyName]) || '';
};

RuleService.prototype.charBoolean = function (propertyName) {
	const charBoolean = this._rules.charBoolean;
	return (charBoolean && charBoolean[propertyName]) || false;
};

RuleService.prototype.attachable = function (propertyName) {
	const attachable = this._rules.attachable;
	return (attachable && attachable[propertyName]) || null;
};

RuleService.prototype.allowEntityActionsWhenInactive = function () {
	return !!this._rules.allowEntityActionsWhenInactive;
};

RuleService.prototype.userActivityNotification = function () {
	return this._rules.userActivityNotification || null;
};

RuleService.prototype.availableNoteTypes = function () {
	return this._rules.availableNoteTypes || null;
};

RuleService.prototype.defaultRemoveFormFlow = function () {
	return this._rules.defaultRemoveFormFlow;
};

RuleService.prototype.defaultRemoveFormFlowForProperty = function (propertyName) {
	return this._rules.defaultRemoveFormFlowForProperties && this._rules.defaultRemoveFormFlowForProperties[propertyName];
};

RuleService.prototype.defaultActivateFormFlow = function () {
	return this._rules.defaultActivateFormFlow;
};

RuleService.prototype.workflow = function () {
	return this._rules.workflow;
};

RuleService.prototype.hideWorkflow = function () {
	return this._rules.hideWorkflow === true;
};

function getRule(ruleService, ruleType, propertyName, okIfNotFound) {
	let result;
	let rules = ruleService._rules[ruleType];
	if (rules) {
		rules = rules[propertyName];
		if (rules) {
			result = rules[0];
		}
	}

	if (!result && !okIfNotFound) {
		/*! SuppressStringValidation Exception message */
		throw new Error(stringUtils.format('Could not find {0} rule for property "{1}" on {2}.', ruleType, propertyName, ruleService.entityName));
	}

	return result || null;
}

export default RuleService;
