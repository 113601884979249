import dialogService from 'DialogService';
import global from 'Global';
import materialDesignNotesDialogService from 'MaterialDesignNotesDialogService';
import materialDesignTrackingDialogService from 'MaterialDesignTrackingDialogService';
import materialDesignWorkflowDialogService from 'MaterialDesignWorkflowDialogService';
import Promise from 'bluebird';

class MaterialDesignDialogService {
	constructor() {
	}

	registerLoader(loader) {
		this.loader = loader;
	}

	unregisterLoader() {
		this.hideAllDialogs();
		delete this.loader;
	}

	canShowDialogs() {
		return this.loader && global.materialDesign && !dialogService.isDialogOpen();
	}

	loadDialogWithPropsAsync(component, { props, on }) {
		function showDialogAsync() {
			props.value = true;
			return new Promise(() => {});
		}

		if (this.loader) {
			return this.loader.loadDialogWithPropsAsync(component, { props, on, showDialogAsync });
		}
		return Promise.reject();
	}

	loadDialogAsync(component, extender) {
		if (this.loader) {
			return this.loader.loadDialogAsync(component, extender);
		}
		return Promise.reject();
	}

	hideAllDialogs() {
		materialDesignNotesDialogService.hideAllDialogs();
		materialDesignWorkflowDialogService.hideAllDialogs();
		materialDesignTrackingDialogService.hideAllDialogs();
		if (this.loader) {
			return this.loader.hideAllDialogs();
		}
	}

	canShowAlertDialog() {
		return this.canShowDialogs();
	}

	async showAlertDialogAsync(title, message, buttons, messageType, messageResultType, customMessage) {
		/*! SuppressStringValidation component/extender name */
		const dialog = await this.loadDialogAsync('WtgAlertDialog', 'AlertDialogExtender');
		return await dialog.showDialogAsync(title, message, buttons, messageType, messageResultType, customMessage);
	}

	canShowChangePasswordDialog() {
		return this.canShowDialogs();
	}

	async showChangePasswordDialogAsync(oldPassword) {
		/*! SuppressStringValidation component/extender name */
		const dialog = await this.loadDialogAsync('WtgChangePasswordDialog', 'ChangePasswordDialogExtender');
		return await dialog.showDialogAsync(oldPassword);
	}

	canShowChangeBranchDepartmentDialog() {
		return this.canShowDialogs();
	}

	async showChangeBranchDepartmentDialogAsync(viewModel) {
		/*! SuppressStringValidation component/extender name */
		const dialog = await this.loadDialogAsync('WtgChangeBranchDepartmentDialog', 'ChangeBranchDepartmentDialogExtender');
		return await dialog.showDialogAsync(viewModel);
	}

	canShowChooseDialog() {
		return this.canShowDialogs();
	}

	async showChooseDialogAsync() {
		/*! SuppressStringValidation component/extender name */
		const dialog = await this.loadDialogAsync('WtgChooseDialog', 'ChooseDialogExtender');
		return await dialog.showDialogAsync.apply(null, arguments);
	}

	canShowErrorDialog() {
		return this.canShowDialogs();
	}

	async showErrorDialogAsync(messageType, message, title, notificationSummary, buttonOptions, filter) {
		/*! SuppressStringValidation component/extender name */
		const dialog = await this.loadDialogAsync('WtgErrorDialog', 'ErrorDialogExtender');
		return await dialog.showDialogAsync(messageType, message, title, notificationSummary, buttonOptions, filter);
	}

	canShowErrorReportingDialog() {
		return this.canShowDialogs();
	}

	async showErrorReportingDialogAsync(errorDetails, environment, errorID) {
		/*! SuppressStringValidation component/extender name */
		const dialog = await this.loadDialogAsync('WtgErrorReportingDialog', 'ErrorReportingDialogExtender');
		return await dialog.showDialogAsync(errorDetails, environment, errorID);
	}

	canShowThemeConfigurationDialog() {
		return this.canShowDialogs();
	}

	async showThemeConfigurationDialogAsync() {
		/*! SuppressStringValidation component/extender name */
		const dialog = await this.loadDialogAsync('WtgThemeConfigurationDialog', 'ThemeConfigurationDialogExtender');
		return await dialog.showDialogAsync();
	}

	canShowConversationDialog() {
		return this.canShowDialogs();
	}

	async showConversationDialogAsync(entity) {
		/*! SuppressStringValidation component/extender name */
		const dialog = await this.loadDialogAsync('WtgConversationDialog', 'ConversationDialogExtender');
		return await dialog.showDialogAsync(entity);
	}

	canShowMessageDialog() {
		return this.canShowDialogs();
	}

	async showMessageDialogAsync(entity, title) {
		/*! SuppressStringValidation component/extender name */
		const dialog = await this.loadDialogAsync('WtgMessageDialog', 'MessageDialogExtender');
		return await dialog.showDialogAsync(entity, title);
	}

	canShowEDocsDialog() {
		return this.canShowDialogs();
	}

	async showEDocsDialogAsync(entity) {
		/*! SuppressStringValidation component/extender name */
		const dialog = await this.loadDialogAsync('WtgEDocsDialog', 'EDocsDialogExtender');
		return await dialog.showDialogAsync(entity);
	}

	canShowProgressDialog() {
		return this.canShowDialogs();
	}

	async showProgressDialogAsync(caption) {
		/*! SuppressStringValidation component/extender name */
		const dialog = await this.loadDialogAsync('WtgProgressDialog', 'ProgressDialogExtender');
		dialog.showDialogAsync(caption);
		return { hide: dialog.hideDialog };
	}

	canShowColorSchemesDialog() {
		return this.canShowDialogs();
	}

	async showColorSchemeManageDialogAsync(props, on) {
		/*! SuppressStringValidation component name */
		const dialog = await this.loadDialogWithPropsAsync('WtgManageColorSchemesDialog', { props, on });
		dialog.showDialogAsync();
		return { hide: dialog.hideDialog };
	}

	async showColorSchemeEditDialogAsync(props, on) {
		/*! SuppressStringValidation component name */
		const dialog = await this.loadDialogWithPropsAsync('WtgEditColorSchemeDialog', { props, on });
		dialog.showDialogAsync();
		return { hide: dialog.hideDialog };
	}
}

export default new MaterialDesignDialogService();
