import Promise from 'bluebird';
import Dependency2 from 'Dependency2';
import entityExpander from 'EntityExpander';
import ko from 'knockout';

function loadDependenciesAsync(entity, rule, options) {
	return Promise.all([
		rule.getCacheDependencyFuncAsync(),
		entityExpander.expandPathsAsync(entity, rule.expandPaths),
	]).then(([cacheDependencyFunc]) => {
		const dependencyPaths = getDependencyPathsForRule(
			rule,
			options && options.excludedProperty
		);
		return Promise.map(dependencyPaths, (path) => {
			return Dependency2.getValueAsync(entity, path);
		}).return({ cacheDependencyFunc, dependencyPaths });
	});
}

export function subscribeToRuleDependenciesLegacyDoNotUseAsync(entity, rule, callback, options) {
	return loadDependenciesAsync(
		entity,
		rule,
		options
	).then(({ cacheDependencyFunc, dependencyPaths }) =>
		subscribeToPaths(dependencyPaths, cacheDependencyFunc, entity, callback, options)
	);
}

function getDependencyPathsForRule(rule, excludedProperty) {
	let result = rule.getDependencyPathsLegacyDoNotUse();
	if (excludedProperty) {
		result = result.filter((path) => {
			return path !== excludedProperty; // Note that path could be a function of excludedProperty, e.g. excludedProperty.Substring() but that is not being excluded at this time.
		});
	}

	return result;
}

function subscribeToPaths(paths, cacheDependencyFunc, entity, callback, options) {
	if (paths.length === 0 && !cacheDependencyFunc) {
		return null;
	}

	let changeCallback = undefined;
	if (cacheDependencyFunc) {
		cacheDependencyFunc = ko
			.pureComputed(cacheDependencyFunc.bind(undefined, entity))
			.extend({ deferred: true });
	}

	const computed = ko.computed(() => {
		for (let i = 0; i < paths.length; i++) {
			Dependency2.getValue(entity, paths[i], options);
		}

		if (cacheDependencyFunc) {
			cacheDependencyFunc();
		}

		if (changeCallback) {
			changeCallback();
		}
	});

	changeCallback = function () {
		ko.ignoreDependencies(callback);
	};

	return computed;
}
